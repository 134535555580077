import { _Dialog } from './Dialog.solid'
import { _Spinner } from './Spinner.solid'
import './SpinnerOverlay.css'

type Props = {
  open?: boolean
  onClose?: () => void
}
export const _SpinnerOverlay = (props: Props) => {
  return (
    <_Dialog
      open={props.open ?? true}
      class="spinner-overlay"
      onClose={props.onClose}
    >
      <_Spinner width="150px" style="margin-inline: auto" />
    </_Dialog>
  )
}
