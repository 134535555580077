import { Cd } from '@/std/codec'
import { O, R, list, struct } from '@/std/data'
import { FormControl } from '@/std/form-control'
import { identity, pipe } from '@/std/function'
import { computed, mapState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Reading } from '@mindpalace/readings/entity'
import { AddReadingAction } from '@mindpalace/readings/use-case/add-reading/client'
import { ListReadingsResource } from '@mindpalace/readings/use-case/list-readings/client'
import { RemoveReadingAction } from '@mindpalace/readings/use-case/remove-reading/client'
import { ScrapePageAction } from '@mindpalace/readings/use-case/scrape-page/client'
import { UpdateReadingAction } from '@mindpalace/readings/use-case/update-reading/client'

export type ReadingsModel = ReturnType<typeof ReadingsModel>
type Deps = {
  url: O.Option<string>
}
export const ReadingsModel = ({ url }: Deps) => {
  const readings = ListReadingsResource()
  const addReading = AddReadingAction()
  const updateReading = UpdateReadingAction()
  const removeReading = RemoveReadingAction()
  const toBrowse = FormControl(O.toUndefined(url) ?? '')
  const scrapedPage = ScrapePageAction()

  const effects = [
    addReading.state.onChange(RR.map(readings.fetch)),
    updateReading.state.onChange(RR.map(readings.fetch)),
    removeReading.state.onChange(RR.map(readings.fetch)),
  ]

  return {
    init: async () => {
      await pipe(
        url,
        O.map(Cd.Url.decode),
        O.map(R.map(scrapedPage.trigger)),
        O.map(R.toUndefined),
        O.toUndefined,
      )
    },
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    readings,
    addReading,
    updateReading,
    removeReading,
    scrapedPage,
    scrapedPageNode: mapState(scrapedPage.state, RR.map(struct.lookup('node'))),
    toBrowse,
    scrapedPageReading: computed([readings, toBrowse], (readings, toBrowse) => {
      const browsedUrl = pipe(
        R.try(() => new URL(toBrowse).href, identity),
        R.unwrapOr(() => ''),
      )
      return pipe(
        readings,
        RR.map(list.findFirst((r) => r.url === browsedUrl)),
        RR.unwrapOr(O.None<Reading>),
      )
    }),
    retryFetchingScrapedPage: async () => {
      if (!toBrowse.isValid()) return
      await scrapedPage.trigger(new URL(toBrowse()))
    },
  }
}
