import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { ReadonlyState, State } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { BinderTab } from '@mindpalace/palace/entity'

export type TabFormModel = ReturnType<typeof TabFormModel>
type FormValues = { name: string }
type Deps = {
  initialValues?: FormValues
  state: ReadonlyState<RR.RemoteResult<any, BinderTab>>
  submit: (values: FormValues) => Promise<unknown>
  onSaved?: (tab: BinderTab) => void
}
export const TabFormModel = ({
  initialValues,
  state,
  submit,
  onSaved,
}: Deps) => {
  const controls = FormGroup({
    name: FormControl(initialValues?.name ?? '', [nonEmpty('required')]),
  })
  const submitted = State(false)

  const effects = [controls.name.onChange(() => submitted.set(false))]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    controls,
    submitted,
    state,
    submit: preventDefault(async () => {
      controls.markTouched()
      submitted.set(true)
      if (!controls.isValid()) return

      await submit({ name: controls.name() })
      const s = state()
      if (RR.isOk(s)) {
        controls.name.set('')
        submitted.set(false)
        onSaved?.(s.value.value)
      }
    }),
  }
}
