import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { O, date } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Letter } from '@mindpalace/mailbox/entity'
import { ArchiveLettersAction } from '@mindpalace/mailbox/use-case/archive-letter/client'
import { Icon } from '@mindpalace/ui-kit'

type Props = {
  letter: Letter
  href?: string
  archive: ArchiveLettersAction
}
export const LetterHeader = (props: Props) => {
  const archiveState = solidState(props.archive.state)
  const link = `/readings?url=${encodeURIComponent(props.letter.item.link)}`
  const channel = new URL(props.letter.feedUrl).hostname.replace('www.', '')
  return (
    <>
      <div class="flex align-start justify-between gap">
        {props.href ? (
          <Link class="nada" href={props.href}>
            <h5>{O.toUndefined(props.letter.item.title)}</h5>
          </Link>
        ) : (
          <h5>{O.toUndefined(props.letter.item.title)}</h5>
        )}

        <small>
          <button
            type="button"
            class="small discrete button"
            onClick={() => props.archive.trigger([props.letter])}
            disabled={RR.isPending(archiveState())}
          >
            <Icon name="trash" />
          </button>
        </small>
      </div>
      <div class="small flex align-center gap-s">
        <p>{O.toUndefined(props.letter.item.creator)}</p>
        <span>|</span>
        <p>
          {pipe(
            props.letter.item.pubDate,
            O.map(date.format({ dateStyle: 'medium' })),
            O.unwrapOr(() => 'undated'),
          )}
        </p>
        <span>|</span>
        <p class="flex align-center gap-s">
          See on <Link href={link}>{channel}</Link>
          <span>•</span>
          <a
            class="flex align-center"
            style="text-align: left; cursor: pointer"
            target="_blank"
            rel="noopener noreferrer"
            href={props.letter.item.link}
          >
            <Icon name="externalLink" />
          </a>
        </p>
      </div>
    </>
  )
}
