import { solidRemoteAction } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { pipe } from '@/std/function'
import { ReadonlyState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Icon } from '@mindpalace/ui-kit'
import { ComponentProps, createSignal, splitProps } from 'solid-js'
import { Reading } from '../entity'
import { AddReadingAction } from '../use-case/add-reading/client'
import { RemoveReadingAction } from '../use-case/remove-reading/client'
import { UpdateReadingAction } from '../use-case/update-reading/client'
import './page-toolbar.css'
import { readNode } from './text-to-speech'

type Props = ComponentProps<'div'> & {
  node: Element
  lang: string
  url: URL
  reading: ReadonlyState<O.Option<Reading>>
  addAction: AddReadingAction
  updateAction: UpdateReadingAction
  removeAction: RemoveReadingAction
}

export const PageToolbar = (props: Props) => {
  const [, rest] = splitProps(props, [
    'node',
    'lang',
    'url',
    'reading',
    'addAction',
    'removeAction',
  ])
  const reading = solidState(props.reading)
  let urlInputRef: HTMLInputElement | undefined
  const [copied, setCopied] = createSignal(false)
  const copyLink = () => {
    urlInputRef?.select()
    document.execCommand('copy')
    setCopied(true)
    setTimeout(() => setCopied(false), 3000)
  }

  return (
    <div {...rest} class={cx(props.class, 'page-toolbar')}>
      <ListenButton
        class="discrete button"
        target={props.node}
        lang={props.lang}
      />

      {pipe(
        reading(),
        O.fold(
          () => (
            <BookmarkButton
              class="discrete button"
              addAction={props.addAction}
              node={props.node}
              url={props.url}
            />
          ),
          (reading) => (
            <>
              <MarkReadButton
                class="discrete button"
                node={props.node}
                reading={reading}
                updateAction={props.updateAction}
                url={props.url}
              />

              <ArchiveButton
                class="discrete button"
                reading={reading}
                removeReading={props.removeAction}
              />
            </>
          ),
        ),
      )}

      <input
        ref={urlInputRef}
        type="text"
        style="opacity: 0; position: fixed; top: -1000px;"
        value={props.url.href}
      />
      <button class="discrete button" onClick={copyLink}>
        {copied() ? 'Copied! ✅' : 'Copy Link 📋'}
      </button>

      <a
        class="discrete button"
        href={props.url.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        Open 🔗 👀
      </a>
    </div>
  )
}

type ListenProps = {
  class: string
  target: Element
  lang: string
}
const ListenButton = (props: ListenProps) => (
  <button
    class={props.class}
    onClick={() => {
      if (speechSynthesis.speaking) speechSynthesis.pause()
      else if (speechSynthesis.paused) speechSynthesis.resume()
      else readNode(props.target, props.lang)
    }}
  >
    Listen 🎧
  </button>
)

type MarkReadProps = {
  class: string
  node: Element
  url: URL
  reading: Reading
  updateAction: UpdateReadingAction
}
const MarkReadButton = (props: MarkReadProps) => {
  const updateAction = solidRemoteAction(props.updateAction)
  return (
    <button
      class={props.class}
      onClick={() =>
        updateAction.trigger(props.reading.id, {
          html: props.node.innerHTML,
          // toggle readAt
          readAt: pipe(
            props.reading.readAt,
            O.fold(
              () => O.Some(new Date()),
              () => O.None(),
            ),
          ),
          url: props.url.href,
        })
      }
      disabled={RR.isPending(updateAction.state())}
    >
      {pipe(
        props.reading.readAt,
        O.fold(
          () => 'Mark as Read 📖',
          () => 'Mark as Unread 📕',
        ),
      )}
    </button>
  )
}

type BookmarkProps = {
  class: string
  url: URL
  node: Element
  addAction: AddReadingAction
}
const BookmarkButton = (props: BookmarkProps) => {
  const addAction = solidRemoteAction(props.addAction)
  return (
    <button
      class={props.class}
      onClick={() =>
        addAction.trigger({
          url: props.url.href,
          html: props.node.innerHTML,
        })
      }
      disabled={RR.isPending(addAction.state())}
    >
      Bookmark 📚
    </button>
  )
}

type ArchiveProps = {
  class: string
  reading: Reading
  removeReading: RemoveReadingAction
}
export const ArchiveButton = (props: ArchiveProps) => {
  const removeState = solidState(props.removeReading.state)
  return (
    <button
      class={props.class}
      onClick={() => props.removeReading.trigger(props.reading.id)}
      disabled={RR.isPending(removeState())}
    >
      Archive <Icon name="trash" />
    </button>
  )
}
