import { matchOr, pipe } from '../function'

const id = 'HttpError' as const
export const HttpError = (detail: {
  code: number
  status: string
  cause?: unknown
  headers?: Record<string, string>
}): HttpError => {
  const message = [`${detail.status} (${detail.code})`, detail.cause]
    .filter(Boolean)
    .join(': ')
  return {
    ...detail,
    headers: detail.headers ?? {},
    id,
    name: id,
    message,
    toString: () => message,
  }
}

export type HttpError = {
  headers: Record<string, string>
  id: 'HttpError'
  name: 'HttpError'
  message: string
  code: number
  status: string
  cause?: unknown
  toString: () => string
}

export const setErrorHeader =
  (name: string, value: string) => (err: HttpError) => (
    (err.headers[name] = value), err
  )
// export const setErrorCookie =
//   (name: string, options: ResponseCookieOptions) => (err: HttpError) => {
//     err.cookies[name] = options
//     return err
//   }

export const matchHttpCode =
  <T>(cases: Record<number | '_', (err: HttpError) => T>) =>
  (error: HttpError): T =>
    pipe(error, matchOr('code')(cases as any))

export const BadRequest = (cause?: unknown) =>
  HttpError({ code: 400, status: 'BadRequest', cause })
export const Unauthorized = (cause?: unknown) =>
  HttpError({ code: 401, status: 'Unauthorized', cause })
export const Forbidden = (cause?: unknown) =>
  HttpError({ code: 403, status: 'Forbidden', cause })
export const NotFound = (cause?: unknown) =>
  HttpError({ code: 404, status: 'NotFound', cause })
export const ServerError = (cause?: unknown) =>
  HttpError({ code: 500, status: 'ServerError', cause })
export const GatewayTimeout = HttpError({ code: 520, status: 'GatewayTimeout' })
