import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Binder, BinderId } from '../../entity'

export type RemoveBinderPayload = ReturnType<typeof RemoveBinderPayload>
const RemoveBinderPayload = T.struct({
  id: BinderId,
})

export const contract = ApiRouteContract('DELETE', '/binder', {
  body: {
    contentType: MimeType.Json,
    codec: RemoveBinderPayload,
  },
  response: {
    status: 200,
    codec: Binder,
    contentType: MimeType.Json,
  },
})
