import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { SubscriptionsResource } from '@mindpalace/mailbox/use-case/list-rss-subscriptions/client'
import { UnsubscribeFromRssAction } from '@mindpalace/mailbox/use-case/unsubscribe-from-rss/client'
import { ConfirmDialog, Failure, SpinnerOverlay } from '@mindpalace/ui-kit'

type Props = {
  list: SubscriptionsResource
  unsubscribe: UnsubscribeFromRssAction
}
export const SubscriptionList = (props: Props) => {
  const subscriptions = solidRemoteResource(props.list)
  const unsubscribeState = solidState(props.unsubscribe.state)
  return (
    <div class="flex column gap-s">
      <ConfirmDialog
        action={props.unsubscribe}
        confirmMessage="Are you unsubscribing from this RSS feed on purpose?"
      />

      {pipe(
        subscriptions(),
        RR.fold2(
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} />,
          flow(
            list.toNonEmpty,
            O.fold(
              () => <div>You don’t have any subscriptions yet</div>,
              list.map((url) => (
                <div class="flex align-center justify-between gap-s">
                  <span class="flex-1">{url.hostname.replace('www.', '')}</span>
                  <div class="flex align-center gap-s">
                    <button
                      class="danger button"
                      onClick={() => props.unsubscribe.trigger([url])}
                      disabled={RR.isPending(unsubscribeState())}
                    >
                      ×
                    </button>
                  </div>
                </div>
              )),
            ),
          ),
        ),
      )}
    </div>
  )
}
