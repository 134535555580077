import { cx } from '@/std/classNames'
import { ComponentProps, splitProps } from 'solid-js'
import './Spinner.css'

type Props = ComponentProps<'div'> & {
  width?: string
}
export const _Spinner = (props: Props) => {
  const [, rest] = splitProps(props, ['width'])
  return (
    <div
      {...rest}
      class={cx('coffee-machine', props.class)}
      style={`--width: ${props.width ?? '300px'}; ${props.style ?? ''}`}
    >
      <div class="coffee-machine-header">
        <div class="button-1" />
        <div class="button-2" />
        <div class="display" />
        <div class="details" />
      </div>
      <div class="coffee-machine-recess">
        <div class="exit" />
        <div class="arm" />
        <div class="liquid" />
        <div class="smoke smoke-1" />
        <div class="smoke smoke-2" />
        <div class="smoke smoke-3" />
        <div class="smoke smoke-4" />
        <div class="cup" />
      </div>
      <div class="coffee-machine-footer" />
    </div>
  )
}
