import { InputFeedback, solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { solidState } from '@/solidjs/state'
import { FailureDialog } from '@mindpalace/ui-kit'
import { ComponentProps, onCleanup, splitProps } from 'solid-js'
import { EditPageFormModel } from './edit-page-form'

type Props = ComponentProps<'form'> & {
  model: EditPageFormModel
  label?: string
}

export const EditPageForm = (props: Props) => {
  onCleanup(props.model.dispose)
  const [, formProps] = splitProps(props, ['model', 'class', 'label'])
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.state)
  const submitted = solidState(props.model.submitted)
  return (
    <>
      <FailureDialog state={submitState()} mapErr={(e) => e.message} />
      <form {...formProps} onSubmit={props.model.submit} class={props.class}>
        <fieldset>
          <label for="name-control">{props.label || 'Page name'}</label>
          <div class="flex gap-s">
            <Input
              id="name-control"
              type="text"
              class="control flex-1"
              autoCapitalize="words"
              control={controls.name}
              placeholder="Mail, Recipes, Frontend, …"
              aria-describedby="name-error"
            />
            <button class="button" type="submit">
              💾
            </button>
          </div>
          {submitted() && (
            <InputFeedback
              id="name-error"
              role="alert"
              control={controls.name}
            />
          )}
        </fieldset>
      </form>
    </>
  )
}
