import { T } from '@/std/type'
import { BinderId } from '@mindpalace/palace/entity'
import { RssFeedItem } from './RssFeed'

export type Letter = ReturnType<typeof Letter>
export const Letter = T.struct({
  binderId: T.option(BinderId),
  feedUrl: T.string,
  item: RssFeedItem, // use feed item url as id.
  readAt: T.option(T.DateFromString),
})

export const LetterSubscriptions = T.array(T.Url)
export type LetterSubscriptions = ReturnType<typeof LetterSubscriptions>
