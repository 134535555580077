import { C } from '@/std/codec'
import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { AccountId, Tag } from '@mindpalace/shared/entity'
import { Opaque } from 'type-fest'
import { RoomId } from './Room'

export type BinderId = Opaque<string, 'BinderId'>
export const BinderId = pipe(T.string, T.map(cast<string, BinderId>, String))

export const Binder = T.struct({
  id: BinderId,
  accountId: AccountId,
  parent: T.struct({
    kind: T.literal('room'),
    id: RoomId,
  }),
  name: T.string,
  tags: T.array(Tag),
  createdAt: T.DateFromString,
})

export type Binder = C.TypeOf<typeof Binder>
