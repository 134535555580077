import { Router } from '@/std/browser'
import { Eq, O } from '@/std/data'
import { flow } from '@/std/function'
import { mapState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { LettersTabModel } from './letters-tab'
import { SubscriptionsTabModel } from './subscriptions-tab'

export type MailboxModel = ReturnType<typeof MailboxModel>
export type MailboxTab = 'subscriptions' | 'letters'
const mailboxTabEq = Eq.fromEquals<MailboxTab>((a, b) => a === b)

type Deps = {
  basePath: string
}
export const MailboxModel = ({ basePath }: Deps) => {
  const { history } = getClientContext()

  const lettersTab = LettersTabModel()
  const subscriptionsTab = SubscriptionsTabModel()
  const router = Router({
    history,
    basePath,
    isSameRoute: mailboxTabEq.equals,
  })({
    '{/}?': () => 'letters',
    '/subscriptions': () => 'subscriptions',
  })

  const effect = [
    subscriptionsTab.subscribeToRssForm.state.onChange(
      RR.map(flow(RR.Ok, subscriptionsTab.subscriptions.set)),
    ),
    subscriptionsTab.unsubscribe.state.onChange(
      RR.map(flow(RR.Ok, subscriptionsTab.subscriptions.set)),
    ),
    lettersTab.pullRssFeeds.state.onChange(
      RR.map((mailbox) => {
        lettersTab.letters.set(RR.Ok(mailbox))
        lettersTab.pullRssFeeds.state.set(RR.NotAsked())
      }),
    ),
    lettersTab.archive.state.onChange(
      RR.map(() => {
        lettersTab.archive.state.set(RR.NotAsked())
        lettersTab.letters.fetch()
      }),
    ),
  ]

  return {
    dispose: () => {
      effect.forEach((effect) => effect.unlisten())
    },
    basePath,
    activeTab: mapState(
      router.active,
      O.unwrapOr((): MailboxTab => 'letters'),
    ),
    lettersTab,
    subscriptionsTab,
  }
}
