import { Router } from '@/std/browser'
import { Eq, O } from '@/std/data'
import { MailboxModel } from '@mindpalace/mailbox/client/mailbox'
import { ExplorerModel } from '@mindpalace/palace/client/explorer'
import { ReadingsModel } from '@mindpalace/readings/client/readings'
import { getClientContext } from '@mindpalace/shared/client.context'

export type Route = Mailbox | PalaceRoute | ReadingsRoute | SandboxRoute

type Mailbox = {
  name: 'mailbox'
  model: MailboxModel
}
type PalaceRoute = {
  name: 'palace'
  model: ExplorerModel
}
type ReadingsRoute = {
  name: 'readings'
  url: string | null
  model: ReadingsModel
}
type SandboxRoute = {
  name: 'sandbox'
}

export type RouterModel = ReturnType<typeof RouterModel>
type Deps = {
  logout: () => void
}
export const RouterModel = ({ logout }: Deps) => {
  const { history } = getClientContext()
  const router = Router({ history, isSameRoute: routeEq.equals })({
    '/mailbox{/*}?': () => ({
      name: 'mailbox',
      model: MailboxModel({ basePath: '/mailbox' }),
    }),
    '/palace{/*}?': () => ({ name: 'palace', model: ExplorerModel() }),
    '/readings{/*}?': ({ url }) => ({
      name: 'readings',
      url: url.searchParams.get('url'),
      model: ReadingsModel({
        url: O.fromNullable(url.searchParams.get('url')),
      }),
    }),
    '/sandbox': () => ({ name: 'sandbox' }),
  })
  return {
    dispose: router.destroy,
    active: router.active,
    logout,
  }
}

const routeEq = Eq.fromEquals<Route>((a, b) => {
  switch (a.name) {
    case 'readings':
      return a.name === b.name && a.url === b.url
    default:
      return a.name === b.name
  }
})
