import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { AccountId } from '@mindpalace/shared/entity'
import { Opaque } from 'type-fest'

export type ReadingId = Opaque<string, 'ReadingId'>
export const ReadingId = pipe(T.string, T.map(cast<string, ReadingId>, String))

export type Reading = ReturnType<typeof Reading>
export const Reading = T.struct({
  id: ReadingId,
  accountId: AccountId,
  url: T.string,
  html: T.string,
  createdAt: T.DateFromString,
  readAt: T.option(T.DateFromString),
})
