import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, isUrl } from '@/std/form-control'
import { State } from '@/std/reactivity'
import { SubscribeToRssAction } from '@mindpalace/mailbox/use-case/subscribe-to-rss/client'

export type SubscribeToRssFormModel = ReturnType<typeof SubscribeToRssFormModel>

type Deps = {
  initialValue?: string
}
export const SubscribeToRssFormModel = ({ initialValue = '' }: Deps = {}) => {
  const controls = FormGroup({
    url: FormControl(initialValue, [isUrl('invalidUrl')]),
  })
  const action = SubscribeToRssAction()
  const submitted = State(false)

  const effects = [controls.url.onChange(() => submitted.set(false))]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    controls,
    state: action.state,
    submitted,
    submit: preventDefault(async () => {
      controls.markTouched()
      submitted.set(true)
      if (!controls.isValid()) return
      await action.trigger([new URL(controls.url())])
      controls.url.set('')
    }),
  }
}
