import { solidRemoteResource } from '@/solidjs/reactivity'
import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Failure, SpinnerOverlay } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { ListReadingsResource } from '../use-case/list-readings/client'
import { ReadingSummary } from './reading-summary.solid'

type Props = ComponentProps<'div'> & {
  list: ListReadingsResource
}
export const ReadingList = (props: Props) => {
  const [, rest] = splitProps(props, ['list'])
  const readings = solidRemoteResource(props.list)

  return (
    <div {...rest}>
      {pipe(
        readings(),
        RR.fold2(
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} retry={readings.fetch} />,
          flow(
            list.toNonEmpty,
            O.fold(
              () => (
                <div class="padded text-center">
                  You don’t have any readings
                </div>
              ),
              list.map((reading, index) => (
                <>
                  {index > 0 && <hr />}
                  <ReadingSummary class="padded" reading={reading} />
                </>
              )),
            ),
          ),
        ),
      )}
    </div>
  )
}
