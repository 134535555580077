import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { ReadonlyState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Letter } from '@mindpalace/mailbox/entity'
import { ArchiveLettersAction } from '@mindpalace/mailbox/use-case/archive-letter/client'
import { LettersResource } from '@mindpalace/mailbox/use-case/open-mail/client'
import { Failure, SpinnerOverlay } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { LetterSummary } from './letter-summary.solid'

type Props = ComponentProps<'div'> & {
  archive: ArchiveLettersAction
  letters: LettersResource
  filter: ReadonlyState<string>
  refetch: () => Promise<unknown>
  opened: ReadonlyState<O.Option<Letter>>
  getOpenLetterLink: (letter: Letter) => string
}
export const Letters = (props: Props) => {
  const [, rest] = splitProps(props, [
    'archive',
    'letters',
    'filter',
    'refetch',
    'opened',
  ])

  const beingArchived = solidState(props.archive.beingArchived)
  const letters = solidRemoteResource(props.letters)
  const filter = solidState(props.filter)
  const opened = solidState(props.opened)

  return (
    <>
      {pipe(
        letters(),
        RR.map(list.filter((l) => l.item.link.includes(filter()))),
        RR.fold2(
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} retry={props.refetch} />,
          flow(
            list.toNonEmpty,
            O.map(
              list.map((letter, index, arr) => {
                const hidden = pipe(
                  beingArchived(),
                  list.some((l) => l.item.link === letter.item.link),
                )
                if (hidden) return null
                return (
                  <>
                    <LetterSummary
                      archive={props.archive}
                      letter={letter}
                      href={props.getOpenLetterLink(letter)}
                    />
                    {index < arr.length - 1 && <hr />}
                  </>
                )
              }),
            ),
            O.map((list) => (
              <div {...rest} class="padded flex column gap">
                {list}
              </div>
            )),
            O.unwrapOr(NoLetters),
          ),
        ),
      )}
    </>
  )
}

const NoLetters = () => (
  <div class="flex-1 card flex column">
    <div class="padded text-center">Your letter box is empty</div>
  </div>
)
