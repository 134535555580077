import { struct } from '@/std/data'
import type { EditorOptions } from '@tiptap/core'

export const TipTapEditor = async (options: Partial<EditorOptions>) => {
  const [Editor, StarterKit, Underline, Link] = await Promise.all([
    import('@tiptap/core').then(struct.lookup('Editor')),
    import('@tiptap/starter-kit').then(struct.lookup('default')),
    import('@tiptap/extension-underline').then(struct.lookup('default')),
    import('@tiptap/extension-link').then(struct.lookup('default')),
  ])

  return new Editor({
    ...options,
    extensions: [StarterKit, Underline.configure({}), Link.configure({})],
  })
}
