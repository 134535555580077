import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { BinderTabId, BinderTabPage, BinderTabPageId } from '../../entity'

export type UpdateBinderTabPagePayload = ReturnType<
  typeof UpdateBinderTabPagePayload
>
const UpdateBinderTabPagePayload = T.struct({
  name: T.string,
  content: T.string,
  tags: T.array(Tag),
})

export const contract = ApiRouteContract(
  'PATCH',
  '/binder-tab/:binderTabId/page/:id',
  {
    params: C.struct({ binderTabId: BinderTabId, id: BinderTabPageId }),
    body: {
      contentType: MimeType.Json,
      codec: UpdateBinderTabPagePayload,
    },
    response: {
      status: 200,
      contentType: MimeType.Json,
      codec: BinderTabPage,
    },
  },
)
