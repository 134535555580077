import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { getClientContext } from '@mindpalace/shared/client.context'
import { contract } from './contract'

export type UnsubscribeFromRssAction = ReturnType<
  typeof UnsubscribeFromRssAction
>
export const UnsubscribeFromRssAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const unsubscribe = (urls: URL[]) => client({ body: urls })
  return RemoteActionToConfirm(unsubscribe)
}
