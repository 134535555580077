import { Link } from '@/solidjs/router'
import { cx } from '@/std/classNames'
import { O, date } from '@/std/data'
import { pipe } from '@/std/function'
import { ComponentProps, splitProps } from 'solid-js'
import { Reading } from '../entity'
import './reading-summary.css'

type Props = Omit<ComponentProps<'a'>, 'href'> & {
  reading: Reading
}

export const ReadingSummary = (props: Props) => {
  const [, rest] = splitProps(props, ['reading', 'class'])
  const href = () => `/readings?url=${encodeURIComponent(props.reading.url)}`

  return (
    <Link
      {...rest}
      class={cx(
        'reading-summary flex column gap',
        props.class,
        O.isSome(props.reading.readAt) && 'is-read',
      )}
      href={href()}
    >
      <small>
        {pipe(
          props.reading.createdAt,
          date.format({ dateStyle: 'full', timeStyle: 'medium' }),
        )}
      </small>
      <div>{props.reading.url}</div>
    </Link>
  )
}
