import { IconProps } from '../props'

// source code: https://iconoir.com Thanks to them!
export const _MailIcon = (props: IconProps) => (
  <svg
    width={props.size}
    height={props.size}
    stroke-width="1.5"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    color="currentColor"
  >
    <path
      d="M7 9L12 12.5L17 9"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z"
      stroke="currentColor"
      stroke-width="1.5"
    ></path>
  </svg>
)
