import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { BinderTabId, BinderTabPage, BinderTabPageId } from '../../entity'

export type RemoveBinderTabPagePayload = ReturnType<
  typeof RemoveBinderTabPagePayload
>
const RemoveBinderTabPagePayload = T.struct({
  id: BinderTabPageId,
  binderTabId: BinderTabId,
})

export const contract = ApiRouteContract('DELETE', '/binder-tab-page', {
  body: {
    contentType: MimeType.Json,
    codec: RemoveBinderTabPagePayload,
  },
  response: {
    status: 200,
    codec: BinderTabPage,
    contentType: MimeType.Json,
  },
})
