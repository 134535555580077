import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { contract } from './contract'

export type SignInResource = ReturnType<typeof SignInResource>
export const SignInResource = (token: string) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const signIn = () => client({ body: { token } })
  return RemoteResource(signIn)
}
