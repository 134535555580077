import { InputFeedback, solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { solidState } from '@/solidjs/state'
import { RR } from '@/std/remote'
import { FailureDialog } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { SubscribeToRssFormModel } from './subscribe-to-rss-form'

type Props = ComponentProps<'form'> & {
  model: SubscribeToRssFormModel
}
export const SubscribeToRssForm = (props: Props) => {
  const [, formProps] = splitProps(props, ['model'])
  const submitted = solidState(props.model.submitted)
  const submitState = solidState(props.model.state)
  const controls = solidFormGroup(props.model.controls)
  return (
    <>
      <FailureDialog state={submitState()} mapErr={(e) => e.message} />
      <form {...formProps} onSubmit={props.model.submit}>
        <fieldset>
          <div class="flex gap-s">
            <Input
              id="url-control"
              type="text"
              class="control flex-1"
              control={controls.url}
              placeholder="https://example.com/rss.xml"
              aria-describedby="url-error"
            />
            <button
              class="button"
              disabled={RR.isPending(submitState())}
              type="submit"
            >
              Subscribe
            </button>
          </div>
          {submitted() && (
            <InputFeedback
              id="url-error"
              control={controls.url}
              Error={{
                invalidUrl: 'Invalid URL',
              }}
            />
          )}
        </fieldset>
      </form>
    </>
  )
}
