import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { contract } from './contract'

export type LettersResource = ReturnType<typeof LettersResource>
export const LettersResource = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const openMail = () => client({})
  return RemoteResource(openMail)
}

// export type OpenMailModel = ReturnType<typeof OpenMailModel>
// export const OpenMailModel = () => {
//   const resource = LettersResource()
//   const filter = State('')
//   const opened = State(O.None<Letter>())
//   return {
//     resource,
//     archive: ArchiveLetterModel(),
//     filter,
//     opened,
//     filteredLetters: computed([resource, filter], (mail, filter) =>
//       pipe(
//         mail,
//         RR.map(list.filter((letter) => letter.item.link.includes(filter))),
//       ),
//     ),
//   }
// }
