import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Reading, ReadingId } from '../../entity'

export type UpdateReadingPayload = ReturnType<typeof UpdateReadingPayload>
const UpdateReadingPayload = T.struct({
  url: T.string,
  html: T.string,
  readAt: T.option(T.DateFromString),
})

export const contract = ApiRouteContract('PATCH', '/reading/:id', {
  params: T.struct({ id: ReadingId }),
  body: {
    codec: UpdateReadingPayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: Reading,
    contentType: MimeType.Json,
  },
})
