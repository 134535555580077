import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Room } from '../../entity'

const AddRoomPayload = T.struct({
  name: T.string,
})
export type AddRoomPayload = ReturnType<typeof AddRoomPayload>

export type AddRoomContract = typeof contract
export const contract = ApiRouteContract('POST', '/room', {
  body: {
    codec: AddRoomPayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: Room,
    contentType: MimeType.Json,
  },
})
