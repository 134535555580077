import { cx } from '@/std/classNames'
import { Editor } from '@tiptap/core'
import {
  Accessor,
  ComponentProps,
  createEffect,
  createSignal,
  onMount,
  splitProps,
} from 'solid-js'
import { Except } from 'type-fest'
import { TipTapEditor as createTipTapEditor } from './vanilla'

export type { Editor }

type Props = Except<ComponentProps<'div'>, 'onChange'> & {
  editorRef?: (editor: Editor) => void
  value: string
  onChange: (nextValue: string) => unknown
}

export const TipTapEditor = (props: Props) => {
  const [, rest] = splitProps(props, ['value', 'onChange', 'editorRef'])

  const ref = (<div {...rest} />) as HTMLDivElement
  let editor: Editor | undefined

  onMount(async () => {
    editor = await createTipTapEditor({
      element: ref,
      content: props.value,
      onUpdate: ({ editor }) => {
        // editor => parent binding.
        const html = editor.getHTML()
        if (html !== props.value) props.onChange(html)
      },
    })
    props.editorRef?.(editor)
  })

  // binding parent => editor
  createEffect(() => {
    const valueFromProps = props.value
    if (!editor || valueFromProps === editor.getHTML()) return
    editor.commands.setContent(valueFromProps)
  })

  return ref
}

type MenuAction =
  | 'h1'
  | 'h2'
  | 'paragraph'
  | 'bold'
  | 'italic'
  | 'strike'
  | 'underline'
  | 'bulletList'
  | 'orderedList'
  | 'quote'
  | 'link'
  | 'code'

const defaultLabelByAction: Record<MenuAction, string> = {
  h1: 'H1',
  h2: 'H2',
  paragraph: '¶',
  bold: 'B',
  italic: 'I',
  strike: 'S',
  underline: 'U',
  bulletList: '•',
  orderedList: '1.',
  quote: '“',
  code: '</>',
  link: '🔗',
}

const actionByName: Record<MenuAction, (editor: Editor) => void> = {
  h1: (editor) => editor.chain().focus().setHeading({ level: 1 }).run(),
  h2: (editor) => editor.chain().focus().setHeading({ level: 2 }).run(),
  paragraph: (editor) => editor.chain().focus().setParagraph().run(),
  bold: (editor) => editor.chain().focus().toggleBold().run(),
  italic: (editor) => editor.chain().focus().toggleItalic().run(),
  strike: (editor) => editor.chain().focus().toggleStrike().run(),
  underline: (editor) => editor.chain().focus().toggleUnderline().run(),
  bulletList: (editor) => editor.chain().focus().toggleBulletList().run(),
  code: (editor) => editor.chain().focus().toggleCode().run(),
  quote: (editor) => editor.chain().focus().toggleBlockquote().run(),
  orderedList: (editor) => editor.chain().focus().toggleOrderedList().run(),
  link: (editor) => {
    if (editor.isActive('link')) return editor.chain().focus().unsetLink().run()

    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) return

    return !url
      ? editor.chain().focus().extendMarkRange('link').unsetLink().run()
      : editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url })
          .run()
  },
}

const IsActive = (
  editor: Accessor<Editor | undefined>,
  name: string,
  attributes?: {},
) => {
  const [get, set] = createSignal(editor()?.isActive(name, attributes) ?? false)
  createEffect(() => {
    editor()?.on('transaction', () => {
      const next = editor()?.isActive(name, attributes) ?? false
      if (next !== get()) set(next)
    })
  })
  return get
}

type MenuProps = {
  editor: Accessor<Editor | undefined>
  actions: MenuAction[]
  labelByAction?: Record<MenuAction, string>
}
export const TipTapEditorMenu = (props: MenuProps) => {
  const labelByAction = props.labelByAction || defaultLabelByAction

  return (
    <div class="tiptap-menu-actions">
      {props.actions.map((name) => {
        const onClick = () => {
          const editor = props.editor()
          if (!editor) return
          actionByName[name](editor)
        }
        const isActive = IsActive(
          props.editor,
          name,
          name === 'h1'
            ? { level: 1 }
            : name === 'h2'
            ? { level: 2 }
            : undefined,
        )
        return (
          <button
            class={cx(`action toggle-${name}`, isActive() && 'is-active')}
            onClick={onClick}
          >
            {labelByAction[name]}
          </button>
        )
      })}
    </div>
  )
}
