import { solidRemoteResource } from '@/solidjs/reactivity'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Failure, SpinnerOverlay } from '@mindpalace/ui-kit'
import { onCleanup } from 'solid-js'
import { AuthenticateModel } from './authenticate'

type Props = {
  model: AuthenticateModel
}
export const Authenticate = (props: Props) => {
  onCleanup(props.model.dispose)
  const resource = solidRemoteResource(props.model.resource)

  return (
    <>
      {pipe(
        resource(),
        RR.fold2(
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} />,
          () => <>Redirecting...</>,
        ),
      )}
    </>
  )
}
