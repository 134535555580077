import { O, list } from '@/std/data'
import { pipe } from '@/std/function'
import { State, computed } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { Letter } from '../entity'
import { ArchiveLettersAction } from '../use-case/archive-letter/client'
import { SubscriptionsResource } from '../use-case/list-rss-subscriptions/client'
import { LettersResource } from '../use-case/open-mail/client'
import { PullRssFeedsAction } from '../use-case/pull-rss-feeds/client'

export type LettersTabModel = ReturnType<typeof LettersTabModel>
export const LettersTabModel = () => {
  const { history } = getClientContext()
  const archive = ArchiveLettersAction()
  const pullRssFeeds = PullRssFeedsAction()
  const subscriptions = SubscriptionsResource()
  const letters = LettersResource()
  const filter = State('')
  const openedLetter = computed([history.current, letters], (url, letters) =>
    pipe(
      O.struct({
        letters: RR.toOption(letters),
        feedUrl: O.fromNullable(url.searchParams.get('feedUrl')),
        link: O.fromNullable(url.searchParams.get('link')),
      }),
      O.flatMap(({ feedUrl, link }) =>
        O.struct({
          feedUrl: O.Some(feedUrl),
          link: O.Some(link),
          letters: RR.toOption(letters),
        }),
      ),
      O.flatMap(({ feedUrl, link, letters }) =>
        pipe(
          letters,
          list.findFirst(
            (letter) => letter.feedUrl === feedUrl && letter.item.link === link,
          ),
        ),
      ),
    ),
  )
  const getOpenLetterLink = (letter: Letter) => {
    const url = new URL(history.current())
    url.searchParams.set('feedUrl', letter.feedUrl)
    url.searchParams.set('link', letter.item.link)
    return url.href
  }

  const getCloseLetterLink = () => {
    const url = new URL(history.current())
    url.search = ''
    return url.href
  }

  return {
    archive,
    pullRssFeeds,
    subscriptions,
    letters,
    filter,
    openedLetter,
    getOpenLetterLink,
    closeLetterLink: getCloseLetterLink(),
  }
}
