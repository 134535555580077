import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Reading } from '../../entity'

export const contract = ApiRouteContract('GET', '/readings', {
  response: {
    status: 200,
    codec: T.array(Reading),
    contentType: MimeType.Json,
  },
})
