import { Router } from '@/std/browser'
import { flow } from '@/std/function'
import { RR } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { AuthenticateModel } from '@mindpalace/shared/client/authenticate'
import { SignInFormModel } from '@mindpalace/shared/client/sign-in-form'
import { Account } from '@mindpalace/shared/entity'
import { AutoSignInResource } from '@mindpalace/shared/use-case/auto-sign-in/client'

export type AuthGuardModel = ReturnType<typeof AuthGuardModel>

type AuthRoute = Authenticate
type Authenticate = {
  name: 'authenticate'
  model: AuthenticateModel
}

type Deps = {
  initialValues?: { email: string }
  onSignedIn: (account: Account) => void
}
export const AuthGuardModel = ({ initialValues, onSignedIn }: Deps) => {
  const { history } = getClientContext()
  const signInForm = SignInFormModel({ initialValues })
  const autoSignIn = AutoSignInResource()

  const router = Router<AuthRoute>({ history })({
    '/authenticate/:token': ({ params, url }) => ({
      name: 'authenticate',
      model: AuthenticateModel({
        token: params.token,
        onSignedIn: flow(onSignedIn, () => {
          const redirectTo = url.searchParams.get('redirectTo')
          if (redirectTo) history.push(redirectTo)
        }),
      }),
    }),
  })

  const effects = [autoSignIn.onChange(RR.map(onSignedIn))]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    route: router.active,
    signInForm,
    autoSignIn,
  }
}
