import { Link } from '@/solidjs/router'

export const NotFoundPage = () => (
  <main class="papered flex justify-center align-center">
    <article class="padded-l card flex column gap text-center">
      <h1>Not Found</h1>
      <p>
        Go back to <Link href="/mailbox">mailbox</Link>?
      </p>
    </article>
  </main>
)
