import { preventDefault } from '@/std/browser'
import { Email } from '@/std/data'
import { FormControl, FormGroup, isEmail, nonEmpty } from '@/std/form-control'
import { getClientContext } from '../client.context'
import { SendSignInMailAction } from '../use-case/send-sign-in-mail/client'

export type SignInFormModel = ReturnType<typeof SignInFormModel>
type Deps = {
  initialValues?: { email: string }
}
export const SignInFormModel = ({ initialValues }: Deps) => {
  const { history } = getClientContext()
  const controls = FormGroup({
    email: FormControl(initialValues?.email ?? '', [
      nonEmpty('required'),
      isEmail('invalidEmail'),
    ]),
  })
  const action = SendSignInMailAction()

  return {
    state: action.state,
    reset: action.reset,
    controls,
    submit: preventDefault(async () => {
      controls.markTouched()
      if (!controls.isValid()) return
      const location = history.current()
      await action.trigger({
        email: controls.email() as Email,
        redirectTo: location.href.replace(location.origin, ''), // path with search
      })
    }),
  }
}
