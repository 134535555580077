import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Tag } from '@mindpalace/shared/entity'
import { Binder, BinderId } from '../../entity'

export type UpdateBinderPayload = C.TypeOf<typeof UpdateBinderPayload>
const UpdateBinderPayload = C.struct({
  name: C.string,
  tags: C.array(Tag),
})

export const contract = ApiRouteContract('PATCH', '/binder/:id', {
  params: C.struct({ id: BinderId }),
  body: {
    contentType: MimeType.Json,
    codec: UpdateBinderPayload,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Binder,
  },
})
