import { RouterProvider } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { match, pipe } from '@/std/function'
import { onCleanup } from 'solid-js'
import { AppModel } from './app'
import { AuthGuard } from './auth-guard.solid'
import { Router } from './router.solid'

type Props = {
  model: AppModel
}
export const App = (props: Props) => {
  onCleanup(props.model.dispose)
  const view = solidState(props.model.view)
  return (
    <RouterProvider value={props.model.history}>
      {pipe(
        view(),
        match('name')({
          authGuard: ({ model }) => <AuthGuard model={model} />,
          router: ({ model }) => <Router model={model} />,
        }),
      )}
    </RouterProvider>
  )
}
