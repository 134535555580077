import { Link } from '@/solidjs/router'
import { O } from '@/std/data'
import { Letter } from '@mindpalace/mailbox/entity'
import { ArchiveLettersAction } from '@mindpalace/mailbox/use-case/archive-letter/client'
import { LetterHeader } from './letter-header.solid'
import './letter-summary.css'

type Props = {
  letter: Letter
  archive: ArchiveLettersAction
  href: string
  snippetMaxLength?: number
  hidden?: boolean
}
export const LetterSummary = (props: Props) => {
  const snippet = O.toUndefined(props.letter.item.contentSnippet) ?? ''
  const snippetMaxLength = props.snippetMaxLength ?? 300

  return (
    <div class="flex column gap-s letter-summary" hidden={props.hidden}>
      <LetterHeader
        letter={props.letter}
        archive={props.archive}
        href={props.href}
      />
      <p>{O.toUndefined(props.letter.item.summary)}</p>
      <Link style="text-align: left; cursor: pointer" href={props.href}>
        {snippet.slice(0, snippetMaxLength)}
        {snippet.length > snippetMaxLength && '…'}
      </Link>
    </div>
  )
}
