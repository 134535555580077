import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { AddReadingPayload, contract } from './contract'

export type AddReadingAction = ReturnType<typeof AddReadingAction>
export const AddReadingAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const add = (body: AddReadingPayload) => client({ body })
  return RemoteAction(add)
}
