import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { Binder, RoomId } from '../../entity'

export type AddBinderPayload = ReturnType<typeof AddBinderPayload>
const AddBinderPayload = T.struct({
  roomId: RoomId,
  name: T.string,
  tags: T.array(Tag),
})

export const contract = ApiRouteContract('POST', '/binder', {
  body: {
    codec: AddBinderPayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: Binder,
    contentType: MimeType.Json,
  },
})
