import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { SendSignInMailPayload, contract } from './contract'

export type SendSignInMailAction = ReturnType<typeof SendSignInMailAction>
export const SendSignInMailAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const sendSignInMail = (body: SendSignInMailPayload) => client({ body })
  return RemoteAction(sendSignInMail)
}
