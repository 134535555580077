import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { ConfirmDialog, Dialog, Dropdown, Icon } from '@mindpalace/ui-kit'
import { onCleanup } from 'solid-js'
import { BinderForm } from './binder/binder-form.solid'
import { BinderView } from './binder/binder-view.solid'
import { ExplorerModel } from './explorer'
import { ExplorerNav } from './explorer-nav.solid'
import './explorer.css'
import { PalaceLayout } from './palace-layout.solid'
import { RoomForm } from './room/room-form.solid'

type Props = {
  model: ExplorerModel
}

export const PalaceExplorer = (props: Props) => {
  onCleanup(props.model.dispose)

  const tree = solidState(props.model.tree)
  const editBinderForm = solidState(props.model.editBinderForm)
  const editRoomForm = solidState(props.model.editRoomForm)
  const activeTabId = solidState(props.model.activeTabId)
  const activePageId = solidState(props.model.activePageId)
  const addBinderForm = solidState(props.model.addBinderForm)
  const openedBinderModel = solidState(props.model.openedBinderModel)

  return (
    <>
      <Dialog
        open={O.isSome(editBinderForm())}
        onClose={() => editBinderForm.set(O.None())}
      >
        {pipe(
          editBinderForm(),
          O.fold(constNull, (model) => <BinderForm model={model} />),
        )}
      </Dialog>
      <Dialog
        open={O.isSome(editRoomForm())}
        onClose={() => editRoomForm.set(O.None())}
      >
        {pipe(
          editRoomForm(),
          O.fold(constNull, (model) => <RoomForm model={model} />),
        )}
      </Dialog>

      <ConfirmDialog
        action={props.model.removeRoom}
        confirmMessage="Are you sure about removing this room?"
      />
      <ConfirmDialog
        action={props.model.removeBinder}
        confirmMessage="Are you sure about removing this binder?"
      />

      <PalaceLayout
        aside={
          <div class="flex column justify-between">
            <ExplorerNav class="flex-1" model={props.model} />
            <div class="padded flex column gap">
              {pipe(
                addBinderForm(),
                O.fold(constNull, (model) => <BinderForm model={model} />),
              )}
              <RoomForm model={props.model.addRoomForm} />
              <button
                class="button"
                type="button"
                onClick={props.model.tree.fetch}
                disabled={RR.isPending(tree())}
              >
                Refresh
              </button>
            </div>
          </div>
        }
        main={pipe(
          openedBinderModel(),
          O.fold(
            () => <div class="padded">No binder opened.</div>,
            (model) => {
              return (
                <div class="explorer-main flex-1 flex column">
                  <div class="padded flex gap align-center justify-between">
                    <h2>{model.binder.name}</h2>
                    <Dropdown
                      position="bottom"
                      listboxId="danger-zone-menu"
                      trigger={
                        <Dropdown.Trigger class="danger button flex gap-xs align-center">
                          <span>Danger Zone</span>
                          <Icon name="ellipsisMenu" />
                        </Dropdown.Trigger>
                      }
                      menu={
                        <Dropdown.Menu>
                          <button
                            role="option"
                            disabled={O.isNone(activePageId())}
                            onClick={flow(
                              props.model.activePageId,
                              O.map((pageId) => {
                                const subModel = O.toUndefined(
                                  model.activeTabPages(),
                                )
                                return subModel?.removePage.trigger({
                                  binderTabId: subModel.tab.id,
                                  id: pageId,
                                })
                              }),
                            )}
                          >
                            Remove Page
                          </button>

                          <button
                            role="option"
                            disabled={O.isNone(activeTabId())}
                            onClick={flow(
                              props.model.activeTabId,
                              O.map((tabId) => {
                                return model.removeTab.trigger({
                                  binderId: model.binder.id,
                                  id: tabId,
                                })
                              }),
                              O.toUndefined,
                            )}
                          >
                            Remove Tab
                          </button>
                          <button
                            role="option"
                            onClick={() => {
                              props.model.removeBinder.trigger({
                                id: model.binder.id,
                              })
                            }}
                          >
                            Remove Binder
                          </button>

                          {model.binder.parent.kind === 'room' && (
                            <button
                              role="option"
                              onClick={() => {
                                props.model.removeRoom.trigger(
                                  model.binder.parent.id,
                                )
                              }}
                            >
                              Remove Room
                            </button>
                          )}
                        </Dropdown.Menu>
                      }
                    />
                  </div>
                  <BinderView model={model} />

                  <ConfirmDialog
                    action={model.removeTab}
                    confirmMessage="Are you sure about removing this tab?"
                  />
                </div>
              )
            },
          ),
        )}
      />
    </>
  )
}
