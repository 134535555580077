import { InputFeedback, solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { solidState } from '@/solidjs/state'
import { RR } from '@/std/remote'
import { FailureDialog } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { SignInFormModel } from './sign-in-form'

type Props = ComponentProps<'form'> & {
  model: SignInFormModel
}
export const SignInForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const controls = solidFormGroup(props.model.controls)
  const submitState = solidState(props.model.state)

  return (
    <>
      <FailureDialog state={submitState()} mapErr={String} />

      <form {...rest} onSubmit={props.model.submit}>
        <fieldset>
          <label>Email</label>
          <Input
            id="email-control"
            type="email"
            class="control"
            control={controls.email}
            placeholder="some@email"
            aria-aria-describedby="email-error"
            required
          />
          <InputFeedback
            id="email-error"
            role="alert"
            control={controls.email}
            Error={{
              invalidEmail: 'Invalid email',
              required: 'Please enter an email',
            }}
          />
        </fieldset>
        <button
          type="submit"
          class="button"
          disabled={RR.isPending(submitState())}
        >
          Send Magic Link
        </button>
      </form>
    </>
  )
}
