import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { DelayedRemoteAction, RR } from '@/std/remote'
import { ComponentProps, JSX, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  message: JSX.Element
  action: DelayedRemoteAction<any, any, any[]>
}
export const _CancelToast = (props: Props) => {
  const [, rest] = splitProps(props, ['action', 'message'])
  const toArchiveAfterDelay = solidState(props.action.toArchive)
  const submitState = solidState(props.action.state)

  return (
    <>
      {pipe(
        toArchiveAfterDelay(),
        O.fold(constNull, () => (
          <div {...rest} class={cx(props.class, 'toast flex align-center gap')}>
            <span>{props.message}</span>
            <button
              class="discrete button"
              type="button"
              onClick={props.action.cancel}
              disabled={RR.isPending(submitState())}
            >
              Cancel ×
            </button>
          </div>
        )),
      )}
    </>
  )
}
