import { RR } from '@/std/remote'
import { Account } from '../entity'
import { SignInResource } from '../use-case/sign-in/client'

type Deps = {
  token: string
  onSignedIn: (account: Account) => void
}
export type AuthenticateModel = ReturnType<typeof AuthenticateModel>
export const AuthenticateModel = ({ token, onSignedIn }: Deps) => {
  const resource = SignInResource(token)
  const effects = [resource.onChange(RR.map(onSignedIn))]
  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    resource,
  }
}
