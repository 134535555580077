import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { contract } from './contract'

export type AutoSignInResource = ReturnType<typeof AutoSignInResource>
export const AutoSignInResource = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const autoSignIn = () => client({})
  return RemoteResource(autoSignIn)
}
