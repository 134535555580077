import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { ReadingId } from '../../entity'
import { UpdateReadingPayload, contract } from './contract'

export type UpdateReadingAction = ReturnType<typeof UpdateReadingAction>
export const UpdateReadingAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const update = (id: ReadingId, body: UpdateReadingPayload) =>
    client({ params: { id }, body })
  return RemoteAction(update)
}
