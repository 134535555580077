import { solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Authenticate } from '@mindpalace/shared/client/authenticate.solid'
import { SignInForm } from '@mindpalace/shared/client/sign-in-form.solid'
import { SpinnerOverlay, Toast } from '@mindpalace/ui-kit'
import { onCleanup } from 'solid-js'
import { AuthGuardModel } from './auth-guard'

type Props = {
  model: AuthGuardModel
}

export const AuthGuard = (props: Props) => {
  onCleanup(props.model.dispose)

  const route = solidState(props.model.route)
  const submitState = solidState(props.model.signInForm.state)
  const autoSigningInState = solidRemoteResource(props.model.autoSignIn)
  return (
    <main
      class="papered page padded-l flex gap align-center justify-center"
      style="max-width: 100%"
    >
      <SpinnerOverlay open={RR.isPending(submitState())} />

      {pipe(
        autoSigningInState(),
        RR.fold(
          constNull,
          () => (
            <Toast
              delay={3000}
              onDismiss={() => autoSigningInState.set(RR.NotAsked())}
            >
              Signing in…
            </Toast>
          ),
          constNull,
          constNull,
        ),
      )}

      {pipe(
        route(),
        O.map(({ model }) => <Authenticate model={model} />),
        O.unwrapOr(() => (
          <SignInForm
            class="padded-l card flex column gap"
            model={props.model.signInForm}
          />
        )),
      )}
    </main>
  )
}
