import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { BinderTabId } from '../../entity'
import { contract } from './contract'

export const ListBinderTabPagesResource = (id: BinderTabId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const get = () => client({ params: { id } })
  return RemoteResource(get)
}
