import { solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { ComponentProps, splitProps } from 'solid-js'
import { AddTabPageFormModel } from './add-tab-page-form'

type Props = ComponentProps<'form'> & {
  model: AddTabPageFormModel
}

export const AddTabPageForm = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const controls = solidFormGroup(props.model.controls)

  return (
    <form {...rest} onSubmit={props.model.submit}>
      <fieldset>
        <div class="flex gap-s">
          <Input
            class="control"
            autoCapitalize="words"
            placeholder="Add a Page"
            control={controls.name}
          />
          <button class="button" type="submit">
            +
          </button>
        </div>
      </fieldset>
    </form>
  )
}
