import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Opaque } from 'type-fest'

export type AccountId = Opaque<string, 'AccountId'>
export const AccountId = pipe(T.string, T.map(cast<string, AccountId>, String))

export type Account = ReturnType<typeof Account>
export const Account = T.struct({
  id: AccountId,
  email: T.Email,
  createdAt: T.DateFromString,
})
