import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { Except } from 'type-fest'
import { BinderTabId } from '../../entity'
import { AddBinderTabPagePayload, contract } from './contract'

export const AddBinderTabPageAction = (binderTabId: BinderTabId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const addBinderTabPage = (
    body: Except<AddBinderTabPagePayload, 'parentId'>,
  ) => client({ body: { ...body, parentId: binderTabId } })
  return RemoteAction(addBinderTabPage)
}
