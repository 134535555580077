import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { AccountId } from '@mindpalace/shared/entity'
import { Opaque } from 'type-fest'

export type RoomId = Opaque<string, 'RoomId'>
export const RoomId = pipe(T.string, T.map(cast<string, RoomId>, String))

export type Room = ReturnType<typeof Room>
export const Room = T.struct({
  id: RoomId,
  accountId: AccountId,
  name: T.string,
  createdAt: T.DateFromString,
})
