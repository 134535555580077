import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Letter } from '../../entity'

export type ArchiveLettersContract = typeof contract
export const contract = ApiRouteContract('DELETE', '/archive-letters', {
  body: {
    codec: T.array(Letter),
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: T.array(Letter),
  },
})
