// @ts-check
import { flow, identity, pipe } from '../function'
import { Eq } from './eq'
import * as O from './option'
import { Ord } from './ord'

export type NonEmpty<T> = [T, ...T[]]

export const isNonEmpty = <T>(list: T[]): list is NonEmpty<T> => list.length > 0
export const isEmpty = <T>(list: T[]): list is [] => list.length === 0

export const includes = <T>(list: T[], value: unknown): value is T =>
  list.includes(value as any)

type Some = <T>(predicate: Predicate<T>) => (a: T[]) => boolean
export const some: Some = (predicate) => (a) => a.some(predicate)

type Every = {
  <T, U extends T>(
    predicate: (value: T, index: number, array: T[]) => value is U,
  ): (a: T[]) => a is U[]
  <T>(predicate: Predicate<T>): (a: T[]) => boolean
}
export const every: Every =
  (predicate): any =>
  (a) =>
    a.every(predicate)

export const nonEmpty = <T>(one: T, ...rest: T[]): NonEmpty<T> => [one, ...rest]

export const of = <T>(value: T | T[]): T[] =>
  Array.isArray(value) ? value : [value]

export const toNonEmpty = O.refine(isNonEmpty)

type Reduce = <Acc, Item>(
  initial: Acc,
  reducer: (acc: Acc, item: Item, index: number, array: Item[]) => Acc,
) => (array: Item[]) => Acc
export const reduce: Reduce = (initial, reducer) => (array) =>
  array.reduce(reducer, initial)

type Concat = <T, U extends T>(one: T[]) => (two: U[]) => T[]
export const concat: Concat = (one) => (two) => one.concat(two)

type Refine<T, U extends T> = (value: T, index: number, self: T[]) => value is U
type Predicate<T> = (value: T, index: number, self: T[]) => unknown // accept falsy values

type Filter = {
  <T, U extends T>(refine: Refine<T, U>): (array: T[]) => U[]
  <T>(predicate: Predicate<T>): (array: T[]) => T[]
}
export const filter: Filter = (predicate) => (array) => array.filter(predicate)

type FilterMap = <A, B>(
  mapper: (item: A, index: number, self: A[]) => O.Option<B>,
) => (array: A[]) => B[]
export const filterMap: FilterMap = (mapper) =>
  reduce([], (acc, item, index, self) =>
    // @ts-ignore
    pipe(
      mapper(item, index, self),
      O.fold(
        () => acc,
        (value) => [...acc, value],
      ),
    ),
  )
type Compact = <A>(list: O.Option<A>[]) => A[]
export const compact: Compact = filterMap(identity)

type Mapper<A, B> = (item: A, index: number, self: A[]) => B

type FlatMap = <A, B>(mapper: Mapper<A, B[]>) => (arr: A[]) => B[]
export const flatMap: FlatMap = (mapper) => (arr) => arr.flatMap(mapper)

export const flatten = <T>(a: T[][]): T[] => a.flat(1)

type Map = <A, B>(mapper: Mapper<A, B>) => (a: A[]) => B[]
export const map: Map = (mapper) => (a) => a.map(mapper)

export const reverse = <T>(arr: T[]) => [...arr].reverse()

type Separate = <T>(predicate: Predicate<T>) => (array: T[]) => [T[], T[]]
export const separate: Separate = (predicate) =>
  reduce([[], []], ([acc1, acc2], item, index, self) =>
    // @ts-ignore
    predicate(item, index, self)
      ? [[...acc1, item], acc2]
      : [acc1, [...acc2, item]],
  )
type Slice = <T>(start: number, end: number) => (a: T[]) => T[]
export const slice: Slice = (start, end) => (a) => a.slice(start, end)

type Sort = <T, U extends T>(ord: Ord<T>) => (a: U[]) => U[]
export const sort: Sort = (ord) => (a) => a.sort(ord.compare)

type FindFirst = <T>(predicate: Predicate<T>) => (a: T[]) => O.Option<T>
export const findFirst: FindFirst = (predicate) => (a) =>
  pipe(a.find(predicate), O.fromNullable)

type FindFirstMap = <T, U>(
  map: Mapper<T, O.Option<U>>,
) => (a: T[]) => O.Option<U>

export const findFirstMap: FindFirstMap = (map) => (a) => {
  for (let index = 0; index < a.length; index++) {
    const option = map(a[index], index, a)
    if (O.isSome(option)) return option
  }
  return O.None()
}

type FindLast = FindFirst
export const findLast: FindLast = (predicate) =>
  flow(reverse, findFirst(predicate))

type Head = <T>(arr: T[]) => O.Option<T>
export const head: Head = (arr) => O.fromNullable(arr[0])

type Tail = Head
export const tail: Tail = (arr) => O.fromNullable(arr[arr.length - 1])

export const size = (a: unknown[]) => a.length

type Join = <T>(separator: string) => (a: T[]) => string
export const join: Join = (separator) => (a) => a.join(separator)

export const uniqueBy = <T>(eq: Eq<T>) =>
  filter<T>(
    (value, index, array) => array.findIndex(eq.equals(value)) === index,
  )

export const eq = <T>(eq: Eq<T>) =>
  Eq.fromEquals<T[]>(
    (a, b) =>
      a.length === b.length &&
      a.every((item, index) => eq.equals(item)(b[index])),
  )
