import { O, list } from '@/std/data'
import { debounce, pipe } from '@/std/function'
import { ReadonlyState, State, computed, mapState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Binder, BinderTab, BinderTabId } from '@mindpalace/palace/entity'
import { AddBinderTabAction } from '@mindpalace/palace/use-case/add-binder-tab/client'
import { ListBinderTabsResource } from '@mindpalace/palace/use-case/list-binder-tabs/client'
import { UpdateBinderTabAction } from '@mindpalace/palace/use-case/update-binder-tab/client'
import { getClientContext } from '@mindpalace/shared/client.context'
import { RemoveBinderTabAction } from '../../use-case/remove-binder-tab/client'
import { TabFormModel } from '../tab/tab-form'
import { TabPagesModel } from '../tab/tab-pages'

export type BinderViewModel = ReturnType<typeof BinderViewModel>
type Deps = {
  binder: Binder
  activeTabId: ReadonlyState<O.Option<BinderTabId>>
}
export const BinderViewModel = ({ binder, activeTabId }: Deps) => {
  const { history } = getClientContext()
  const tabs = ListBinderTabsResource(binder.id)
  const addTab = AddBinderTabAction(binder.id)
  const addTabForm = TabFormModel({
    state: addTab.state,
    submit: (values) => addTab.trigger({ tags: [], ...values }),
    onSaved: onTabAdded,
  })
  const editTabForm = State(O.None<TabFormModel>())
  const addTabFormOpened = State(false)
  const removeTab = RemoveBinderTabAction()
  const updateTab = UpdateBinderTabAction(binder.id)
  const activeTab = computed([tabs, activeTabId], (tabs, tabId) => {
    return pipe(
      O.struct({ tabs: RR.toOption(tabs), tabId }),
      O.flatMap(({ tabs, tabId }) =>
        pipe(
          tabs,
          list.findFirst((tab) => tab.id === tabId),
        ),
      ),
    )
  })

  const refetchTabs = debounce(500)(tabs.fetch)

  const effects = [
    tabs.onChange(
      RR.map((tabs) => {
        if (O.isSome(activeTab())) return
        const [firstTab] = tabs
        if (!firstTab) return
        history.push(getTabOpenLink(firstTab))
      }),
    ),
    addTabForm.state.onChange(RR.map(onTabAdded)),
    removeTab.state.onChange(RR.map(onTabRemoved)),
  ]

  const onTabEdited = () => {
    editTabForm.set(O.None())
    tabs.fetch()
  }

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    activeTab,
    activeTabPages: mapState(
      activeTab,
      O.map((tab) => TabPagesModel({ tab })),
    ),
    editTabForm,
    binder,
    tabs,
    addTabForm,
    addTabFormOpened,
    removeTab,
    getTabOpenLink,
    editTab,
  }

  function editTab(binderTab: BinderTab) {
    const model = TabFormModel({
      initialValues: binderTab,
      state: updateTab.state,
      submit: (values) => {
        return updateTab.trigger(binderTab.id, { tags: [], ...values })
      },
      onSaved: onTabEdited,
    })
    editTabForm.set(O.Some(model))
  }

  function onTabAdded(tab: BinderTab) {
    addTabFormOpened.set(false)
    history.push(getTabOpenLink(tab))
    refetchTabs()
  }

  function onTabRemoved() {
    history.push(getCloseTabLink())
    refetchTabs()
  }

  function getTabOpenLink(tab: BinderTab) {
    const url = new URL(history.current())
    url.searchParams.set('tabId', tab.id)
    return url.href
  }
  function getCloseTabLink() {
    const url = new URL(history.current())
    url.searchParams.delete('tabId')
    url.searchParams.delete('pageId')
    return url.href
  }
}
