import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { LetterSubscriptions } from '../../entity'

export const contract = ApiRouteContract('GET', '/rss-subscriptions', {
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: LetterSubscriptions,
  },
})
