import { SpinnerOverlay } from '@mindpalace/ui-kit'
import { createSignal } from 'solid-js'

export const Sandbox = () => {
  const [opened, setOpened] = createSignal(true)
  return (
    <div class="flex justify-center align-center">
      <div class="padded">
        <button class="button" onClick={() => setOpened(!opened())}>
          Open
        </button>
      </div>

      <SpinnerOverlay open={opened()} onClose={() => setOpened(false)} />
    </div>
  )
}
