import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Reading } from '../../entity'

export type AddReadingPayload = ReturnType<typeof AddReadingPayload>
const AddReadingPayload = T.struct({
  url: T.string,
  html: T.string,
})

export const contract = ApiRouteContract('POST', '/reading', {
  body: {
    codec: AddReadingPayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: Reading,
    contentType: MimeType.Json,
  },
})
