import { curry2, flow, identity, pipe } from '../function'
import * as list from './list'
import { number } from './number'
import { Ord } from './ord'

export const isValid = (date: Date): date is Date =>
  !Number.isNaN(date.valueOf())

export const isDate = (value: unknown): value is Date =>
  value instanceof Date && isValid(value)

export const toIsoString = (d: Date) => d.toISOString()

export const fromString = (str: string) => new Date(str)

export const utcFromString = (str: string) =>
  fromString(str.endsWith('Z') ? str : str + 'Z')

export const addDays = curry2((date: Date, count: number) => {
  return make(clone(date).setUTCDate(date.getUTCDate() + count))
})

export const addMs = curry2((date: Date, ms: number) =>
  make(date.valueOf() + ms),
)

export const addSeconds = curry2((date: Date, seconds: number) =>
  make(date.valueOf() + seconds * 1000),
)

export const addMinutes = curry2((date: Date, minutes: number) =>
  make(date.valueOf() + minutes * 60 * 1000),
)

export const addHours = curry2((date: Date, hours: number) =>
  make(date.valueOf() + hours * 24 * 60 * 1000),
)

export const addMonths = curry2((date: Date, count: number) =>
  make(clone(date).setUTCMonth(date.getUTCMonth() + count)),
)

export const make = (...params: ConstructorParameters<typeof Date>) =>
  new Date(...params)

export const clone = make as (date: Date) => Date

export const isBefore = curry2(
  (date: Date, comparator: Date) => date.valueOf() < comparator.valueOf(),
)

export const isSame = curry2(
  (date: Date, comparator: Date) => date.valueOf() === comparator.valueOf(),
)

export const isAfter = curry2(
  (date: Date, comparator: Date) =>
    !isBefore(date, comparator) && !isSame(date, comparator),
)

export const isSameOrBefore = curry2(
  (date: Date, comparator: Date) =>
    isBefore(comparator)(date) || isSame(date, comparator),
)

export const isSameOrAfter = curry2(
  (date: Date, comparator: Date) =>
    isAfter(date, comparator) || isSame(date, comparator),
)

/**
 * time Possible shape: 'hh', 'hh:mm', 'hh:mm:ss' or 'hh:mm:ss.ms'
 */
export const setTime = curry2((date: Date, time: string) => {
  const [h = 0, m = 0, s = 0, ms = 0] = time
    .replace('.', ':')
    .split(':')
    .map(Number)
  return make(clone(date).setUTCHours(h, m, s, ms))
})

export const startOfDay = (date: Date) => {
  // If time is between midnight and for 4am (-1 millisecond), start of the day was yesterday at 4am.
  const time = date.toISOString().slice(11, -1)
  const shouldSubtract1Day = time <= '03:59:59.999' && time >= '00:00:00'
  return pipe(
    date,
    clone,
    setTime('04:00:00'),
    shouldSubtract1Day ? addDays(-1) : identity,
  )
}

export const endOfDay = flow(startOfDay, addDays(1), addMs(-1))

export const startOfWeek = flow(
  clone,
  // replace 0 - sunday by 7 for the computation to be correct
  (date) => date.setUTCDate(date.getUTCDate() - (date.getUTCDay() || 7) + 1),
  make,
  startOfDay,
)
export const endOfWeek = flow(startOfWeek, addDays(6), endOfDay)

export const startOfMonth = flow(
  clone,
  (date) => date.setUTCDate(1),
  make,
  startOfDay,
)
export const endOfMonth = flow(
  startOfMonth,
  addMonths(1),
  addDays(-1),
  endOfDay,
)

export const isBetween = (from: Date, to: Date) => (date: Date) =>
  isSameOrAfter(date, from) && isSameOrBefore(date, to)

export const isSameDay = curry2((date: Date, comparator: Date) =>
  isBetween(startOfDay(comparator), endOfDay(comparator))(date),
)

export const isSameWeek = curry2((date: Date, comparator: Date) =>
  isBetween(startOfWeek(comparator), endOfWeek(comparator))(date),
)

export const isSameMonth = curry2((date: Date, comparator: Date) =>
  isBetween(startOfMonth(comparator), endOfMonth(comparator))(date),
)

export const ord = Ord.fromCompare<Date>((a, b) =>
  number.ord.compare(a.valueOf(), b.valueOf()),
)

export const eq = Ord.toEq(ord)

export const latest = (dates: Date[]) => pipe(dates, list.sort(ord), list.tail)
export const earliest = (dates: Date[]) =>
  pipe(dates, list.sort(ord), list.head)

export type RangeOptions = { from: Date; until: Date }
export const range = (options: RangeOptions) => {
  const isBeforeLast = isBefore(options.until)
  const isSameDayThanLast = isSameDay(options.until)
  const acc: Date[] = []
  let current = options.from
  while (isBeforeLast(current) && !isSameDayThanLast(current)) {
    acc.push(current)
    current = addDays(current, 1)
  }
  return acc
}

export const now = (): Date => new Date()

export type FormatOptions = Intl.DateTimeFormatOptions & { locale?: string }

let globalFormatOptions: FormatOptions | undefined = undefined
export const setGlobalFormatLocale = (next: FormatOptions) => {
  globalFormatOptions = next
}

export const format = curry2((date: Date, options: FormatOptions) =>
  new Intl.DateTimeFormat(options.locale ?? globalFormatOptions?.locale, {
    ...globalFormatOptions,
    ...options,
  }).format(date),
)
