import { solidState } from '@/solidjs/state'
import { constNull, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { CancelToast, FailureDialog } from '@mindpalace/ui-kit'
import { ComponentProps, onMount, splitProps } from 'solid-js'
import { BrowseForm } from './browse-form.solid'
import { PageToolbar } from './page-toolbar.solid'
import { ReadingList } from './reading-list.solid'
import { ReadingsModel } from './readings'
import { ReadingsLayout } from './readings-layout.solid'
import './readings.css'
import { ScrapedPage } from './scraped-page.solid'

type Props = ComponentProps<'div'> & {
  model: ReadingsModel
}

export const Readings = (props: Props) => {
  onMount(props.model.init)

  const [, rest] = splitProps(props, ['model', 'class'])
  const scrapedPage = solidState(props.model.scrapedPage.state)
  const addReadingState = solidState(props.model.addReading.state)
  const updateReadingState = solidState(props.model.updateReading.state)

  return (
    <>
      <CancelToast
        message="Archiving reading…"
        action={props.model.removeReading}
      />
      <FailureDialog state={addReadingState()} mapErr={String} />
      <FailureDialog state={updateReadingState()} mapErr={String} />

      <ReadingsLayout
        {...rest}
        readings={<ReadingList list={props.model.readings} />}
        page={
          <ScrapedPage
            class="padded"
            node={props.model.scrapedPageNode}
            retry={props.model.retryFetchingScrapedPage}
          />
        }
        toolbar={
          <>
            <BrowseForm class="padded" control={props.model.toBrowse} />
            {pipe(
              scrapedPage(),
              RR.map(({ lang, node, url }) => (
                <>
                  <hr />
                  <PageToolbar
                    class="padded"
                    addAction={props.model.addReading}
                    updateAction={props.model.updateReading}
                    removeAction={props.model.removeReading}
                    lang={lang}
                    node={node}
                    reading={props.model.scrapedPageReading}
                    url={url}
                  />
                </>
              )),
              RR.unwrapOr(constNull),
            )}
          </>
        }
      />
    </>
  )
}
