import { RR } from '@/std/remote'
import { SubscriptionsResource } from '../use-case/list-rss-subscriptions/client'
import { UnsubscribeFromRssAction } from '../use-case/unsubscribe-from-rss/client'
import { SubscribeToRssFormModel } from './subscriptions/subscribe-to-rss-form'

export type SubscriptionsTabModel = ReturnType<typeof SubscriptionsTabModel>
export const SubscriptionsTabModel = () => {
  const subscribeToRssForm = SubscribeToRssFormModel()
  const subscriptions = SubscriptionsResource()
  const unsubscribe = UnsubscribeFromRssAction()

  const effects = [
    subscribeToRssForm.state.onChange(RR.map(subscriptions.fetch)),
    unsubscribe.state.onChange(RR.map(subscriptions.fetch)),
  ]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    subscribeToRssForm,
    subscriptions,
    unsubscribe,
  }
}
