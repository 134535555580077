import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { getClientContext } from '@mindpalace/shared/client.context'
import { RemoveBinderTabPayload, contract } from './contract'

export const RemoveBinderTabAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const remove = (body: RemoveBinderTabPayload) => client({ body })
  return RemoteActionToConfirm(remove)
}
