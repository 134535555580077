import { solidFormControl } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { Link } from '@/solidjs/router'
import { FormControl } from '@/std/form-control'
import { ComponentProps, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  control: FormControl<string, any>
}

export const BrowseForm = (props: Props) => {
  const [, rest] = splitProps(props, ['control'])
  const control = solidFormControl(props.control)
  const href = () => `/readings?url=${encodeURIComponent(control())}`

  return (
    <div {...rest}>
      <div class="flex gap-s">
        <Input
          type="URL"
          class="control"
          placeholder="https://htmhell.dev"
          control={control}
          required
        />
        <Link class="button" href={href()} aria-disabled={!control()}>
          Browse
        </Link>
      </div>
    </div>
  )
}
