import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { BinderTabId, BinderTabPage } from '../../entity'

export type AddBinderTabPagePayload = ReturnType<typeof AddBinderTabPagePayload>
const AddBinderTabPagePayload = T.struct({
  parentId: BinderTabId,
  name: T.string,
  content: T.string,
  tags: T.array(Tag),
})

export const contract = ApiRouteContract('POST', '/binder-tab-page', {
  body: {
    codec: AddBinderTabPagePayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: BinderTabPage,
    contentType: MimeType.Json,
  },
})
