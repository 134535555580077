import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { BinderId, BinderTab, BinderTabId } from '../../entity'

export type RemoveBinderTabPayload = ReturnType<typeof RemoveBinderTabPayload>
const RemoveBinderTabPayload = T.struct({
  id: BinderTabId,
  binderId: BinderId,
})

export const contract = ApiRouteContract('DELETE', '/binder-tab', {
  body: {
    contentType: MimeType.Json,
    codec: RemoveBinderTabPayload,
  },
  response: {
    status: 200,
    codec: BinderTab,
    contentType: MimeType.Json,
  },
})
