import { Icon } from '@mindpalace/ui-kit'
import { JSX, onCleanup, onMount } from 'solid-js'
import './readings-layout.css'

type Props = {
  readings: JSX.Element
  toolbar: JSX.Element
  page: JSX.Element
}

export const ReadingsLayout = (props: Props) => {
  let inputRef: HTMLInputElement | undefined
  let asideRef: HTMLElement | undefined
  const onClickAway = (event: MouseEvent) => {
    const target = event.target as Element
    if (!inputRef?.checked || target === inputRef) return
    if (target.classList.contains('burger-trigger')) return
    if (!asideRef?.contains(target)) inputRef.checked = false
  }

  onMount(() => {
    document.addEventListener('click', onClickAway)
  })
  onCleanup(() => {
    document.removeEventListener('click', onClickAway)
  })
  return (
    <div class="readings-layout">
      <input
        ref={inputRef}
        id="readings-burger"
        type="checkbox"
        aria-label="Toggle menu"
        style="opacity: 0; position: absolute; top: -1000px;"
      />
      <aside ref={asideRef} class="aside">
        {props.readings}
        <label for="readings-burger" class="burger-trigger">
          <Icon name="bookStack" />
        </label>
      </aside>
      <div class="sidebar-separator">
        <hr class="vertical" />
      </div>
      <div class="toolbar-content flex column">
        <div class="toolbar">{props.toolbar}</div>
        <hr />
        <div class="content">{props.page}</div>
      </div>
    </div>
  )
}
