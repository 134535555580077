import { History } from '@/std/browser'
import { Email, O } from '@/std/data'
import { State, computed } from '@/std/reactivity'
import { provideClientContext } from '@mindpalace/shared/client.context'
import { Account } from '@mindpalace/shared/entity'
import { LogoutAction } from '@mindpalace/shared/use-case/logout/client'
import { AuthGuardModel } from './auth-guard'
import { Config } from './config'
import { RouterModel } from './router'

export type AppModel = ReturnType<typeof AppModel>
type Deps = {
  config: Config
  history: History
}
export const AppModel = ({ config, history }: Deps) => {
  const storedEmail = window.localStorage.getItem('email') as Email | null
  const account = State(O.None<Account>())
  provideClientContext({
    account,
    apiUrl: config.apiUrl,
    history,
  })

  const logoutAction = LogoutAction()
  const logout = () => {
    logoutAction.trigger().then(() => account.set(O.None()))
  }

  const effects = [
    account.onChange(
      O.fold(
        () => {
          window.localStorage.removeItem('email')
        },
        (account) => {
          window.localStorage.setItem('email', account.email)
        },
      ),
    ),
  ]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },
    history,
    view: computed(
      [account],
      O.fold(
        () => ({
          name: 'authGuard' as const,
          model: AuthGuardModel({
            initialValues: { email: storedEmail ?? '' },
            onSignedIn: (a) => {
              account.set(O.Some(a))
              if (history.current().pathname.startsWith('/authenticate')) {
                history.push('/palace')
              }
            },
          }),
        }),
        (account) => ({
          name: 'router' as const,
          model: RouterModel({ logout }),
        }),
      ),
      (a, b) => a.name === b.name,
    ),
  }
}

export type AppView =
  | { name: 'authGuard'; model: AuthGuardModel }
  | { name: 'router'; model: RouterModel }
