import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { Except } from 'type-fest'
import { BinderId } from '../../entity'
import { AddBinderTabPayload, contract } from './contract'

export const AddBinderTabAction = (binderId: BinderId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const addBinderTab = (body: Except<AddBinderTabPayload, 'parentId'>) =>
    client({ body: { ...body, parentId: binderId } })
  return RemoteAction(addBinderTab)
}
