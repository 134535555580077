import { O, list } from '@/std/data'
import { debounce, defer, flow, pipe } from '@/std/function'
import { State, computed, mapState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { T } from '@/std/type'
import { AddBinderAction } from '@mindpalace/palace/use-case/add-binder/client'
import { RemoveBinderAction } from '@mindpalace/palace/use-case/remove-binder/client'
import { RemoveRoomAction } from '@mindpalace/palace/use-case/remove-room/client'
import { UpdateBinderAction } from '@mindpalace/palace/use-case/update-binder/client'
import { UpdateRoomAction } from '@mindpalace/palace/use-case/update-room/client'
import { getClientContext } from '@mindpalace/shared/client.context'
import {
  Binder,
  BinderId,
  BinderTabId,
  BinderTabPageId,
  Room,
  RoomId,
} from '../entity'
import { AddRoomAction } from '../use-case/add-room/client'
import { ExploreResource } from '../use-case/explore/client'
import { BinderFormModel } from './binder/binder-form'
import { BinderViewModel } from './binder/binder-view'
import { RoomFormModel } from './room/room-form'

export type ExplorerModel = ReturnType<typeof ExplorerModel>
export const ExplorerModel = () => {
  const { history } = getClientContext()
  const tree = ExploreResource()
  const refetchTree = debounce(500)(tree.fetch)

  const removeRoom = RemoveRoomAction()
  const addBinder = AddBinderAction()
  const removeBinder = RemoveBinderAction()
  const updateRoom = UpdateRoomAction()
  const updateBinder = UpdateBinderAction()
  const addRoom = AddRoomAction()
  const addRoomForm = RoomFormModel({
    state: addRoom.state,
    submit: (values) => addRoom.trigger({ ...values }),
    onSaved: refetchTree,
  })
  const getSearchParameter = <T extends string>(
    name: string,
    type: T.Type<T, any>,
  ) => {
    const { decode } = T.option(type)
    return mapState(
      history.current,
      flow(
        (url) => url.searchParams.get(name),
        decode,
        O.fromResult,
        O.flatten,
      ),
    )
  }

  const activeRoomId = getSearchParameter('roomId', RoomId)
  const activeBinderId = getSearchParameter('binderId', BinderId)
  const activeTabId = getSearchParameter('tabId', BinderTabId)
  const activePageId = getSearchParameter('pageId', BinderTabPageId)

  const editRoomForm = State(O.None<RoomFormModel>())
  const editBinderForm = State(O.None<BinderFormModel>())
  const openedRoom = computed([tree, activeRoomId], (tree, roomId) => {
    return pipe(
      O.struct({ roomId, tree: RR.toOption(tree) }),
      O.flatMap(({ tree, roomId }) =>
        pipe(
          tree.rooms,
          list.findFirst((room) => room.id === roomId),
        ),
      ),
    )
  })
  const openedBinder = computed([tree, activeBinderId], (tree, binderId) => {
    return pipe(
      O.struct({ binderId, tree: RR.toOption(tree) }),
      O.flatMap(({ tree, binderId }) =>
        pipe(
          tree.binders,
          list.findFirst((binder) => binder.id === binderId),
        ),
      ),
    )
  })

  const effects = [
    removeRoom.state.onChange(RR.map(onRoomRemoved)),
    removeBinder.state.onChange(RR.map(onBinderRemoved)),
    updateRoom.state.onChange(RR.map(refetchTree)),
    updateBinder.state.onChange(RR.map(refetchTree)),
    addRoomForm.state.onChange(RR.map(refetchTree)),
    addBinder.state.onChange(RR.map(refetchTree)),
    tree.onChange(
      RR.map(
        defer((tree) => {
          // defer: opened room depends on tree as well. Wait for it to resolve.
          if (O.isSome(openedRoom())) return
          const [firstRoom] = tree.rooms
          if (!firstRoom) return
          const firstBinder = tree.binders.find(
            (b) => b.parent.kind === 'room' && b.parent.id === firstRoom.id,
          )
          const next = firstBinder
            ? getBinderOpenLink(firstRoom, firstBinder)
            : getRoomOpenLink(firstRoom)
          history.push(next)
        }),
      ),
    ),
  ]

  const getRoomOpenLink = (room: Room) => {
    const url = new URL(history.current())
    url.searchParams.delete('binderId')
    url.searchParams.set('roomId', room.id)
    return url.href
  }
  const getBinderOpenLink = (room: Room, binder: Binder) => {
    const url = new URL(history.current())
    url.searchParams.set('roomId', room.id)
    url.searchParams.set('binderId', binder.id)
    return url.href
  }

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    openedRoom,
    openedBinder,
    editBinderForm,
    editRoomForm,
    removeBinder,
    removeRoom,
    activeTabId,
    activePageId,
    getBinderOpenLink,
    getRoomOpenLink,
    editBinder,
    editRoom,
    tree,
    addRoomForm,
    addBinderForm: mapState(
      openedRoom,
      O.map((room) => {
        return BinderFormModel({
          onSaved: refetchTree,
          state: addBinder.state,
          submit: (values) =>
            addBinder.trigger({
              roomId: room.id,
              tags: [],
              ...values,
            }),
        })
      }),
    ),
    openedBinderModel: mapState(
      openedBinder,
      O.map((binder) => BinderViewModel({ binder, activeTabId })),
    ),
  }

  function editBinder(binder: Binder) {
    const model = BinderFormModel({
      state: updateBinder.state,
      onSaved: onEdited,
      submit: (values) => {
        return updateBinder.trigger(binder.id, {
          tags: [],
          ...values,
        })
      },
    })
    editBinderForm.set(O.Some(model))
  }

  function editRoom(room: Room) {
    const model = RoomFormModel({
      state: updateRoom.state,
      submit: (values) => updateRoom.trigger(room.id, values),
      onSaved: onEdited,
    })
    editRoomForm.set(O.Some(model))
  }

  function onEdited() {
    editRoomForm.set(O.None())
    editBinderForm.set(O.None())
    tree.fetch()
  }

  function onBinderRemoved() {
    history.push(getBinderCloseLink())
    tree.fetch()
  }

  function onRoomRemoved() {
    history.push(getRoomCloseLink())
    tree.fetch()
  }

  function getRoomCloseLink() {
    const url = new URL(history.current())
    url.searchParams.delete('roomId')
    url.searchParams.delete('binderId')
    url.searchParams.delete('tabId')
    url.searchParams.delete('pageId')
    return url.href
  }

  function getBinderCloseLink() {
    const url = new URL(history.current())
    url.searchParams.delete('binderId')
    url.searchParams.delete('tabId')
    url.searchParams.delete('pageId')
    return url.href
  }
}
