import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { O, list, set } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { computed } from '@/std/reactivity'
import { DelayedRemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { Letter } from '../../entity'
import { contract } from './contract'

export type ArchiveLettersAction = ReturnType<typeof ArchiveLettersAction>
export const ArchiveLettersAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const archiveLetter = (body: Letter[]) => client({ body })
  const action = DelayedRemoteAction(archiveLetter, 3000)
  return {
    ...action,
    beingArchived: computed(
      [action.toArchive, action.archivingInBackground],
      (toArchive, archivingInBackground) =>
        pipe(
          toArchive,
          O.unwrapOr(() => [] as Letter[][]),
          list.concat(pipe(archivingInBackground, set.toArray, list.flatten)),
          list.flatten,
        ),
    ),
  }
}
