import { cx } from '@/std/classNames'
import { Icon } from '@mindpalace/ui-kit'
import { ComponentProps, JSX, onCleanup, onMount, splitProps } from 'solid-js'
import './palace-layout.css'

type Props = ComponentProps<'div'> & {
  aside: JSX.Element
  main: JSX.Element
}

export const PalaceLayout = (props: Props) => {
  const [, rest] = splitProps(props, ['aside', 'main'])
  let inputRef: HTMLInputElement | undefined
  let asideRef: HTMLElement | undefined
  const onClickAway = (event: MouseEvent) => {
    const target = event.target as Element
    if (!inputRef?.checked || target === inputRef) return
    if (target.classList.contains('burger-trigger')) return
    if (!asideRef?.contains(target)) inputRef.checked = false
  }

  onMount(() => {
    document.addEventListener('click', onClickAway)
  })
  onCleanup(() => {
    document.removeEventListener('click', onClickAway)
  })

  return (
    <div {...rest} class={cx(props.class, 'palace-layout')}>
      <input
        ref={inputRef}
        id="palace-burger"
        type="checkbox"
        aria-label="Toggle menu"
        style="opacity: 0; position: absolute; top: -1000px;"
      />
      <aside ref={asideRef} class="aside">
        {props.aside}

        <label for="palace-burger" class="burger-trigger">
          <Icon name="burger" />
        </label>
      </aside>
      <div class="sidebar-separator">
        <hr class="vertical" />
      </div>
      <div class="main">{props.main}</div>
    </div>
  )
}
