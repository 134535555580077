import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { match, pipe } from '@/std/function'
import { Mailbox } from '@mindpalace/mailbox/client/mailbox.solid'
import { PalaceExplorer } from '@mindpalace/palace/client/explorer.solid'
import { Readings } from '@mindpalace/readings/client/readings.solid'
import { onCleanup } from 'solid-js'
import { AppHeader } from './app-header.solid'
import { NotFoundPage } from './page-not-found.solid'
import { RouterModel } from './router'
import { Sandbox } from './sandbox.solid'

type Props = {
  model: RouterModel
}
export const Router = (props: Props) => {
  onCleanup(props.model.dispose)
  const route = solidState(props.model.active)
  return (
    <div class="papered page flex column" style="min-height: 100vh;">
      <AppHeader
        active={O.toNull(route())?.name}
        paths={{
          mailbox: '/mailbox',
          palace: '/palace',
          readings: '/readings',
        }}
        logout={props.model.logout}
      />

      <hr style="height: 2px" />

      {pipe(
        route(),
        O.map(
          match('name')({
            mailbox: ({ model }) => <Mailbox model={model} />,
            palace: ({ model }) => <PalaceExplorer model={model} />,
            readings: ({ model }) => <Readings model={model} />,
            sandbox: () => <Sandbox />,
          }),
        ),
        O.unwrapOr(() => <NotFoundPage />),
      )}
    </div>
  )
}
