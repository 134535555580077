import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteResource } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { BinderId } from '../../entity'
import { contract } from './contract'

export const ListBinderTabsResource = (binderId: BinderId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const get = () => client({ params: { binderId } })
  return RemoteResource(get)
}
