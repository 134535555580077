import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { pipe } from '@/std/function'
import { MimeType } from '@/std/http'

export const contract = ApiRouteContract('GET', '/scrape-page', {
  searchParams: C.struct({
    url: pipe(
      C.string,
      C.map(decodeURIComponent, encodeURIComponent),
      C.compose(Cd.Url),
    ),
  }),
  response: {
    status: 200,
    codec: C.string,
    contentType: MimeType.Html,
  },
})
