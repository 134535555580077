import { ApiRouteContract } from '@/std/api-contract'
import { C, Cd } from '@/std/codec'
import { MimeType } from '@/std/http'
import { LetterSubscriptions } from '../../entity'

export const contract = ApiRouteContract('POST', '/subscribe-to-rss', {
  body: {
    codec: C.struct({ urls: C.array(Cd.Url) }),
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: LetterSubscriptions,
    contentType: MimeType.Json,
  },
})
