import { IconProps } from './props'
import { _BookStackIcon } from './svg/book-stack.solid'
import { _BookmarkIcon } from './svg/bookmark.solid'
import { _BurgerIcon } from './svg/burger.solid'
import { _EditIcon } from './svg/edit.solid'
import { _EllipsisMenuIcon } from './svg/ellipsis-menu.solid'
import { _ExternalLinkIcon } from './svg/external-link.solid'
import { _HomeIcon } from './svg/home.solid'
import { _LogoutIcon } from './svg/logout.solid'
import { _MailOpenedIcon } from './svg/mail-opened.solid'
import { _MailIcon } from './svg/mail.solid'
import { _PagesIcon } from './svg/pages.solid'
import { _SaveIcon } from './svg/save.solid'
import { _TrashIcon } from './svg/trash.solid'

const icons = {
  bookmark: _BookmarkIcon,
  bookStack: _BookStackIcon,
  burger: _BurgerIcon,
  edit: _EditIcon,
  ellipsisMenu: _EllipsisMenuIcon,
  externalLink: _ExternalLinkIcon,
  home: _HomeIcon,
  logout: _LogoutIcon,
  mail: _MailIcon,
  mailOpened: _MailOpenedIcon,
  pages: _PagesIcon,
  save: _SaveIcon,
  trash: _TrashIcon,
} as const

export type IconName = keyof typeof icons

type Props = Partial<IconProps> & {
  name: IconName
}

export const _Icon = (props: Props) => {
  const Component = icons[props.name]
  return <Component size={props.size ?? '1em'} />
}
