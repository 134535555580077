import { solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, list, struct } from '@/std/data'
import { pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { Accordion, Failure, Icon, SpinnerOverlay } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import { Binder, Room } from '../entity'
import { ExplorerModel } from './explorer'
import './explorer-nav.css'

type Props = ComponentProps<'nav'> & {
  model: ExplorerModel
}

export const ExplorerNav = (props: Props) => {
  const [, rest] = splitProps(props, ['model'])
  const { history } = getClientContext()

  const tree = solidRemoteResource(props.model.tree)
  const openedRoom = solidState(props.model.openedRoom)
  const openedBinder = solidState(props.model.openedBinder)

  const isRoomOpened = (room: Room) =>
    O.toUndefined(openedRoom())?.id === room.id

  const isBinderOpened = (binder: Binder) =>
    O.toUndefined(openedBinder())?.id === binder.id

  const toggleRoom = (room: Room) => {
    if (isRoomOpened(room)) return
    history.push(props.model.getRoomOpenLink(room))
  }

  return (
    <nav {...rest} class={cx(props.class, 'explorer-nav')}>
      {pipe(
        tree(),
        RR.fold2(
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} retry={tree.fetch} />,
          ({ rooms, binders }) =>
            pipe(
              rooms,
              list.sort(struct.ord('name', Room.ord.name)),
              list.toNonEmpty,
              O.fold(
                () => <>You don’t have rooms yet</>,
                list.map((room) => (
                  <Accordion
                    class={cx(
                      'room-treeitem',
                      isRoomOpened(room) && 'is-opened',
                    )}
                    summary={
                      <div class="flex justify-between align-center gap">
                        <div>{room.name}</div>
                        <button
                          class="mini discrete button"
                          onClick={(event) => {
                            event.stopPropagation()
                            props.model.editRoom(room)
                          }}
                        >
                          <Icon name="edit" />
                        </button>
                      </div>
                    }
                    open={isRoomOpened(room)}
                    onToggle={() => toggleRoom(room)}
                  >
                    <div class="flex column">
                      {pipe(
                        binders,
                        list.sort(struct.ord('name', Binder.ord.name)),
                        list.filter(
                          (b) =>
                            b.parent.kind === 'room' && b.parent.id === room.id,
                        ),
                        list.toNonEmpty,
                        O.fold(
                          () => <>No binders</>,
                          list.map((binder) => (
                            <div class="binder-treeitem flex justify-between gap">
                              <Link
                                class="binder-treeitem"
                                aria-disabled={isBinderOpened(binder)}
                                href={props.model.getBinderOpenLink(
                                  room,
                                  binder,
                                )}
                              >
                                {binder.name}
                              </Link>

                              <button
                                class="mini discrete button"
                                onClick={() => props.model.editBinder(binder)}
                              >
                                <Icon name="edit" />
                              </button>
                            </div>
                          )),
                        ),
                      )}
                    </div>
                  </Accordion>
                )),
              ),
            ),
        ),
      )}
    </nav>
  )
}
