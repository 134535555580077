import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { BinderId, BinderTab, BinderTabId } from '../../entity'

export type UpdateBinderTabPayload = ReturnType<typeof UpdateBinderTabPayload>
const UpdateBinderTabPayload = T.struct({
  name: T.string,
  tags: T.array(Tag),
})

export const contract = ApiRouteContract('PATCH', '/binder/:binderId/tab/:id', {
  params: C.struct({ binderId: BinderId, id: BinderTabId }),
  body: {
    contentType: MimeType.Json,
    codec: UpdateBinderTabPayload,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: BinderTab,
  },
})
