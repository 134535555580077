import { ComponentProps, JSX, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  heading?: JSX.Element
  error: { name: string; message: string }
  message?: (error: { name: string; message: string }) => JSX.Element
  retry?: () => unknown
  Retry?: JSX.Element
}
export const _Failure = (props: Props) => {
  const [, divProps] = splitProps(props, [
    'error',
    'heading',
    'message',
    'retry',
    'Retry',
  ])
  return (
    <div {...divProps} role="alert">
      <header>
        <h3>{props.heading ?? 'An error occurred'}</h3>
      </header>
      <p>{props.message?.(props.error) ?? props.error.message}</p>
      {props.Retry && <footer>{props.Retry}</footer>}
      {props.retry && (
        <footer>
          <button class="button" type="button">
            Retry
          </button>
        </footer>
      )}
    </div>
  )
}
