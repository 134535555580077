import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { Opaque } from 'type-fest'
import { BinderTabId } from './BinderTab'

export type BinderTabPageId = Opaque<string, 'BinderTabPageId'>
export const BinderTabPageId = pipe(
  T.string,
  T.map(cast<string, BinderTabPageId>, String),
)

export type BinderTabPage = ReturnType<typeof BinderTabPage>

export const BinderTabPage = T.struct({
  id: BinderTabPageId,
  parentId: BinderTabId,
  name: T.string,
  content: T.string,
  tags: T.array(Tag),
  createdAt: T.DateFromString,
})
