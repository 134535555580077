import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Letter } from '../../entity'

export const contract = ApiRouteContract('GET', '/pull-rss-feeds', {
  response: {
    status: 200,
    codec: T.array(Letter),
    contentType: MimeType.Json,
  },
})
