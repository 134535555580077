import { ApiRouteContract } from '@/std/api-contract'
import { C } from '@/std/codec'
import { MimeType } from '@/std/http'
import { Room, RoomId } from '../../entity'

export const contract = ApiRouteContract('DELETE', '/room/:id', {
  params: C.struct({ id: RoomId }),
  response: {
    status: 200,
    codec: Room,
    contentType: MimeType.Json,
  },
})
