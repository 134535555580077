import { ComponentProps, JSX, createEffect, splitProps } from 'solid-js'

type Props = Omit<ComponentProps<'details'>, 'onToggle'> & {
  summary: JSX.Element
  onToggle: (
    event: ToggleEvent & { currentTarget: HTMLDetailsElement },
  ) => unknown
}
export const _Accordion = (props: Props) => {
  const [, rest] = splitProps(props, ['open', 'summary', 'onToggle'])
  let element: HTMLDetailsElement | undefined = undefined

  createEffect(() => {
    const isOpened = !!props.open
    if (element) element.open = isOpened
  })

  return (
    <details
      {...rest}
      onToggle={(event) => {
        if (event.currentTarget.open === props.open) return
        props.onToggle(event as any)
      }}
      ref={element}
    >
      <summary>{props.summary}</summary>

      {props.children}
    </details>
  )
}
