import { Link } from '@/solidjs/router'
import { cx } from '@/std/classNames'
import { Icon, Logo, LogoText } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'
import './app-header.css'
import { Route } from './router'

type Props = ComponentProps<'header'> & {
  logout: () => void
  active: Route['name'] | undefined
  paths: {
    mailbox: string
    palace: string
    readings: string
  }
}
export const AppHeader = (props: Props) => {
  const [, rest] = splitProps(props, ['logout', 'paths'])
  return (
    <header
      {...rest}
      class={cx(
        props.class,
        'app-header flex gap justify-between align-center',
      )}
    >
      <div class="inline-padded flex align-center gap">
        <Logo height="2.5em" />
        <LogoText class="logo-text" height="1em" />
      </div>
      <nav class="flex justify-center">
        <Link
          class={cx(props.active === 'palace' && 'is-active')}
          href={props.paths.palace}
        >
          <Icon name="home" /> <small>Palace</small>
        </Link>
        <Link
          class={cx(props.active === 'mailbox' && 'is-active')}
          href={props.paths.mailbox}
        >
          <Icon name="mail" />
          {'  '}
          <small>Mailbox</small>
        </Link>
        <Link
          class={cx(props.active === 'readings' && 'is-active')}
          href={props.paths.readings}
        >
          <Icon name="bookmark" /> <small>Readings</small>
        </Link>
        <button class="link" onClick={props.logout}>
          <Icon name="logout" /> <small>Log out</small>
        </button>
      </nav>
    </header>
  )
}
