import { cx } from '@/std/classNames'
import { ComponentProps, onCleanup, onMount, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  onDismiss: () => void
  delay: number
}
export const _Toast = (props: Props) => {
  const [, rest] = splitProps(props, ['onDismiss', 'delay'])

  let timeout: ReturnType<typeof setTimeout> | undefined
  onMount(() => {
    timeout = setTimeout(() => {
      props.onDismiss()
      timeout = undefined
    }, props.delay)
  })
  onCleanup(() => {
    if (timeout) clearTimeout(timeout)
  })

  return (
    <div {...rest} class={cx(props.class, 'toast flex align-center gap')}>
      <div>{props.children}</div>
      <button
        class="discrete button"
        type="button"
        onClick={props.onDismiss}
        aria-label="Cancel"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  )
}
