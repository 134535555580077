import { solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { O, list, struct } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Dialog, Failure, Icon, SpinnerOverlay } from '@mindpalace/ui-kit'
import { ComponentProps, onCleanup, splitProps } from 'solid-js'
import { BinderTab } from '../../entity'
import { TabForm } from '../tab/tab-form.solid'
import { TabPages } from '../tab/tab-pages.solid'
import { BinderViewModel } from './binder-view'

type Props = ComponentProps<'div'> & {
  model: BinderViewModel
}

export const BinderView = (props: Props) => {
  onCleanup(props.model.dispose)

  const [, rest] = splitProps(props, ['model'])
  const tabs = solidRemoteResource(props.model.tabs)
  const addTabFormOpened = solidState(props.model.addTabFormOpened)
  const editTabForm = solidState(props.model.editTabForm)
  const activeTabPages = solidState(props.model.activeTabPages)
  const activeTab = solidState(props.model.activeTab)
  const isTabActive = (tab: BinderTab) => O.toNull(activeTab())?.id === tab.id

  return (
    <div {...rest} class="flex-1 flex column">
      <Dialog open={addTabFormOpened()} class="padded">
        <TabForm model={props.model.addTabForm} />
      </Dialog>

      <Dialog
        open={O.isSome(editTabForm())}
        onClose={() => editTabForm.set(O.None())}
      >
        {pipe(
          editTabForm(),
          O.fold(constNull, (model) => <TabForm model={model} />),
        )}
      </Dialog>

      <nav role="tablist" class="underlined-tabs">
        {pipe(
          tabs(),
          RR.fold2(
            () => <SpinnerOverlay />,
            (err) => <Failure error={err} retry={tabs.fetch} />,
            flow(
              list.sort(struct.ord('name', BinderTab.ord.name)),
              list.map((tab) => (
                <Link
                  role="tab"
                  class="flex align-center gap pointer"
                  href={props.model.getTabOpenLink(tab)}
                  aria-selected={isTabActive(tab)}
                  onClick={(event) => {
                    if (!isTabActive(tab)) return
                    event.preventDefault()
                    props.model.editTab(tab)
                  }}
                >
                  <span>{tab.name}</span>
                  {isTabActive(tab) && <Icon name="edit" />}
                </Link>
              )),
            ),
          ),
        )}
        <button
          role="tab"
          aria-selected="false"
          onClick={() => addTabFormOpened.set(true)}
        >
          +
        </button>
      </nav>

      {pipe(
        activeTabPages(),
        O.fold(constNull, (model) => <TabPages model={model} />),
      )}
    </div>
  )
}
