import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Room, RoomId } from '../../entity'

export type UpdateRoomPayload = ReturnType<typeof UpdateRoomPayload>
const UpdateRoomPayload = T.struct({
  name: T.string,
})

export const contract = ApiRouteContract('PATCH', '/room/:id', {
  params: T.struct({ id: RoomId }),
  body: {
    contentType: MimeType.Json,
    codec: UpdateRoomPayload,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: Room,
  },
})
