import { preventDefault } from '@/std/browser'
import { getClientContext } from '@mindpalace/shared/client.context'

export const proxyHtml = (root: Element) => {
  proxyDocumentAnchors(root)
  import('prismjs')
    .then(({ default: Prism }) => Prism.highlightAllUnder(root))
    .catch(console.error)
}

const proxyDocumentAnchors = (root: Element) => {
  const { history } = getClientContext()
  for (const anchor of root.querySelectorAll('a')) {
    anchor.addEventListener(
      'click',
      preventDefault(() => {
        const target = encodeURIComponent(anchor.href)
        const uri = `/readings?url=${target}`
        history.push(uri)
      }),
    )
  }
}
