import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { AddRoomPayload, contract } from './contract'

export type AddRoomAction = ReturnType<typeof AddRoomAction>
export const AddRoomAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const addRoom = (body: AddRoomPayload) => client({ body })
  return RemoteAction(addRoom)
}
