import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { BinderTabId } from '@mindpalace/palace/entity'
import { AddBinderTabPageAction } from '@mindpalace/palace/use-case/add-binder-tab-page/client'
import { AddBinderTabPagePayload } from '@mindpalace/palace/use-case/add-binder-tab-page/contract'

export type AddTabPageFormModel = ReturnType<typeof AddTabPageFormModel>
type Deps = {
  binderTabId: BinderTabId
  initialValues?: AddBinderTabPagePayload
}
export const AddTabPageFormModel = ({ binderTabId, initialValues }: Deps) => {
  const controls = FormGroup({
    name: FormControl(initialValues?.name ?? '', [nonEmpty('required')]),
  })

  const action = AddBinderTabPageAction(binderTabId)

  return {
    state: action.state,
    controls,
    submit: preventDefault(async () => {
      controls.markTouched()
      if (!controls.isValid()) return
      await action.trigger({
        name: controls.name(),
        content: '',
        tags: [],
      })
      controls.name.set('')
    }),
  }
}
