import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { RoomId } from '../../entity'
import { UpdateRoomPayload, contract } from './contract'

export const UpdateRoomAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const update = (id: RoomId, body: UpdateRoomPayload) =>
    client({ params: { id }, body })
  return RemoteAction(update)
}
