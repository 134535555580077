import { O, list } from '@/std/data'
import { cnst, pipe } from '@/std/function'
import { T } from '@/std/type'

const arrayOfString = pipe(
  T.option(T.array(T.string)),
  T.map(O.unwrapOr(cnst<string[]>([])), O.fromPredicate(list.isNonEmpty)),
)

export const RssFeedPaginationLinks = T.struct({
  self: T.option(T.string),
  first: T.option(T.string),
  next: T.option(T.string),
  last: T.option(T.string),
  prev: T.option(T.string),
})
export type RssFeedPaginationLinks = ReturnType<typeof RssFeedPaginationLinks>

export const RssFeedImage = T.struct({
  link: T.string,
  url: T.string,
  title: T.string,
})
export type RssFeedImage = ReturnType<typeof RssFeedImage>

export const RssFeedItemEnclosure = T.struct({
  url: T.string,
  length: T.option(T.number),
  type: T.option(T.string),
})
export type RssFeedItemEnclosure = ReturnType<typeof RssFeedItemEnclosure>

export const RssFeedItem = T.struct({
  link: T.string,
  guid: T.option(T.string),
  title: T.option(T.string),
  pubDate: T.option(T.DateFromString),
  creator: T.option(T.string),
  summary: T.option(T.string),
  content: T.option(T.string),
  isoDate: T.option(T.string),
  categories: arrayOfString,
  contentSnippet: T.option(T.string),
  enclosure: T.option(RssFeedItemEnclosure),
})
export type RssFeedItem = ReturnType<typeof RssFeedItem>

export const RssFeed = T.struct({
  image: T.option(RssFeedImage),
  paginationLinks: T.option(RssFeedPaginationLinks),
  link: T.option(T.string),
  title: T.option(T.string),
  items: T.array(RssFeedItem),
  // `feedUrl` can be used as unique identifier
  feedUrl: T.option(T.string),
  description: T.option(T.string),
  // itunes: T.option(Itunes),
})
export type RssFeed = ReturnType<typeof RssFeed>

// const Itunes = T.struct({
//   image: T.option(T.string),
//   owner: T.option(
//     T.struct({
//       name: T.option(T.string),
//       email: T.option(T.string),
//     }),
//   ),
//   author: T.option(T.string),
//   summary: T.option(T.string),
//   explicit: T.option(T.string),
//   categories: pipe(
//     T.string,
//     T.array,
//     T.option,
//     T.map(
//       O.unwrapOr(() => []),
//       O.fromPredicate(list.isNonEmpty),
//     ),
//   ),
//   keywords: pipe(
//     T.string,
//     T.array,
//     T.option,
//     T.map(
//       O.unwrapOr(() => []),
//       O.fromPredicate(list.isNonEmpty),
//     ),
//   ),
// })
