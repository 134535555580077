import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { State } from '@/std/reactivity'
import { BinderTabPage } from '../../entity'
import { UpdateBinderTabPageAction } from '../../use-case/update-binder-tab-page/client'

export type EditPageFormModel = ReturnType<typeof EditPageFormModel>
type Deps = {
  binderTabPage: () => BinderTabPage
  onSaved?: () => void
}
export const EditPageFormModel = ({ binderTabPage, onSaved }: Deps) => {
  const action = UpdateBinderTabPageAction(binderTabPage().parentId)
  const controls = FormGroup({
    name: FormControl(binderTabPage().name, [nonEmpty('required')]),
  })
  const submitted = State(false)

  const effects = [controls.name.onChange(() => submitted.set(false))]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    controls,
    submitted,
    state: action.state,
    submit: preventDefault(async () => {
      controls.markTouched()
      submitted.set(true)
      if (!controls.isValid()) return

      await action.trigger(binderTabPage().id, {
        content: binderTabPage().content,
        name: controls.name(),
        tags: [],
      })
      controls.name.set('')
      submitted.set(false)
      onSaved?.()
    }),
  }
}
