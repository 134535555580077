import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { BinderTabId, BinderTabPage } from '../../entity'

export const contract = ApiRouteContract('GET', '/binder-tab/:id/pages', {
  params: T.struct({ id: BinderTabId }),
  response: {
    status: 200,
    codec: T.array(BinderTabPage),
    contentType: MimeType.Json,
  },
})
