import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { BinderId, BinderTabId } from '../../entity'
import { UpdateBinderTabPayload, contract } from './contract'

export const UpdateBinderTabAction = (binderId: BinderId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const update = (id: BinderTabId, body: UpdateBinderTabPayload) =>
    client({ params: { binderId, id }, body })
  return RemoteAction(update)
}
