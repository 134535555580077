import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O } from '@/std/data'
import { cnst, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { ComponentProps, JSX, splitProps } from 'solid-js'
import { _Dialog } from './Dialog.solid'

type Props = ComponentProps<'dialog'> & {
  action: RemoteActionToConfirm<any, any, any>
  confirmMessage: JSX.Element
  pendingMessage?: JSX.Element
  heading?: JSX.Element
  no?: JSX.Element
  yes?: JSX.Element
  tone?: 'discrete' | 'danger'
}

export const _ConfirmDialog = (props: Props) => {
  const [, rest] = splitProps(props, [
    'action',
    'confirmMessage',
    'pendingMessage',
    'heading',
    'no',
    'yes',
    'tone',
  ])
  const submitState = solidState(props.action.state)
  const toConfirm = solidState(props.action.toConfirm)
  return (
    <_Dialog {...rest} open={O.isSome(toConfirm())}>
      <header>
        <h3>{props.heading || '⚠️ Warning'}</h3>
      </header>
      {pipe(
        submitState(),
        RR.fold(
          cnst(props.confirmMessage),
          () =>
            props.pendingMessage || (
              <div>Farewell, say goodbye to that room…</div>
            ),
          (e) => (
            <div class="flex column gap-s">
              <div>An error occurred, you can click continue to retry</div>
              <details>
                <summary>Detail</summary>
                {e.id}: {e.message}
              </details>
            </div>
          ),
          cnst(props.confirmMessage),
        ),
      )}
      <footer>
        <form method="dialog" class="flex justify-end gap">
          <button
            class="button"
            disabled={RR.isPending(submitState())}
            type="submit"
          >
            {props.no || 'Nope 😱'}
          </button>

          <button
            class={cx('button', props.tone)}
            disabled={RR.isPending(submitState())}
            type="button"
            onClick={props.action.confirm}
          >
            {props.yes || 'I am'}
          </button>
        </form>
      </footer>
    </_Dialog>
  )
}
