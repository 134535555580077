import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { LetterSubscriptions } from '../../entity'

export const contract = ApiRouteContract('DELETE', '/unsubscribe-from-rss', {
  body: {
    codec: T.array(T.Url),
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: LetterSubscriptions,
  },
})
