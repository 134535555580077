import { cnst, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { JSX } from 'solid-js'
import { _Dialog } from './Dialog.solid'

type Props<E> = {
  state: RR.RemoteResult<E, unknown>
  mapErr: (err: E) => JSX.Element
  heading?: JSX.Element
}

export const _FailureDialog = <E extends any>(props: Props<E>) => {
  return (
    <_Dialog open={RR.isErr(props.state)}>
      <header>
        <h3>{props.heading ?? 'Oops'}</h3>
      </header>
      <p>
        {pipe(props.state, RR.mapErr(props.mapErr), RR.unwrapErrOr(cnst('')))}
      </p>
      <footer>
        <form method="dialog" class="flex justify-end">
          <button class="discrete button" type="submit">
            Close
          </button>
        </form>
      </footer>
    </_Dialog>
  )
}
