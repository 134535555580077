import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'

const Payload = T.struct({
  email: T.Email,
  redirectTo: T.string,
})
export type SendSignInMailPayload = ReturnType<typeof Payload>

export const contract = ApiRouteContract('POST', '/send-auth-mail', {
  body: {
    codec: Payload,
    contentType: MimeType.Json,
  },
  response: { status: 204 },
})
