import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { ReadonlyState } from '@/std/reactivity'
import { ComponentProps, splitProps } from 'solid-js'
import { MailboxTab } from './mailbox'

type Props = ComponentProps<'nav'> & {
  basePath: string
  activeTab: ReadonlyState<MailboxTab>
}
export const MailboxNavTabs = (props: Props) => {
  const [, rest] = splitProps(props, ['activeTab'])
  const activeTab = solidState(props.activeTab)
  return (
    <nav {...rest} role="tablist" class={cx(props.class, 'underlined-tabs')}>
      <Link
        role="tab"
        href={props.basePath}
        aria-selected={activeTab() === 'letters'}
      >
        Mail
      </Link>
      <Link
        role="tab"
        href={`${props.basePath}/subscriptions`}
        aria-selected={activeTab() === 'subscriptions'}
      >
        Subscriptions
      </Link>
    </nav>
  )
}
