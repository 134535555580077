import { solidFormGroup } from '@/solidjs/form'
import { Input } from '@/solidjs/form/Input'
import { solidRemoteAction, solidRemoteResource } from '@/solidjs/reactivity'
import { Link } from '@/solidjs/router'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, list, struct } from '@/std/data'
import { constNull, flow, pipe } from '@/std/function'
import { RR } from '@/std/remote'
import { Editor, TipTapEditor, TipTapEditorMenu } from '@/tiptap/solid'
import {
  ConfirmDialog,
  Dialog,
  Failure,
  Icon,
  SpinnerOverlay,
  SuccessToast,
} from '@mindpalace/ui-kit'
import {
  ComponentProps,
  createSignal,
  onCleanup,
  onMount,
  splitProps,
} from 'solid-js'
import { BinderTabPage } from '../../entity'
import { AddTabPageForm } from '../page/add-tab-page-form.solid'
import { EditPageForm } from '../page/edit-page-form.solid'
import { TabPagesModel } from './tab-pages'
import { TabPagesLayout } from './tab-pages-layout.solid'
import './tab-pages.css'

type Props = ComponentProps<'div'> & {
  model: TabPagesModel
}

export const TabPages = (props: Props) => {
  onMount(props.model.init)
  onCleanup(props.model.dispose)

  const [editor, setEditor] = createSignal<Editor | undefined>(undefined)
  const [, rest] = splitProps(props, ['model'])
  const pages = solidRemoteResource(props.model.pages)
  const activePage = solidState(props.model.activePage)
  const controls = solidFormGroup(props.model.controls)
  const updatePage = solidRemoteAction(props.model.updatePage)
  const editPageNameForm = solidState(props.model.editPageNameForm)
  const isPageOpened = (page: BinderTabPage) =>
    O.toNull(activePage())?.id === page.id

  return (
    <TabPagesLayout
      {...rest}
      class="tab-pages"
      main={
        <div class="padded editor-container">
          <Dialog
            open={O.isSome(editPageNameForm())}
            onClose={() => editPageNameForm.set(O.None())}
          >
            {pipe(
              editPageNameForm(),
              O.fold(constNull, (model) => <EditPageForm model={model} />),
            )}
          </Dialog>

          <ConfirmDialog
            action={props.model.removePage}
            confirmMessage="Are you sure to remove this page?"
          />

          <SuccessToast delay={3000} submitState={props.model.updatePage.state}>
            ✅ Page Updated
          </SuccessToast>

          <div class="flex column gap-s">
            <Input
              class="h2"
              style="max-width: calc(100vw - var(--space-m) * 2);"
              type="text"
              control={controls.name}
            />
            <hr />
            <TipTapEditorMenu
              editor={editor}
              actions={[
                'h1',
                'h2',
                'paragraph',
                'bold',
                'italic',
                'strike',
                'underline',
                'orderedList',
                'bulletList',
                'quote',
                'code',
                'link',
              ]}
            />
            <hr />
            <TipTapEditor
              editorRef={setEditor}
              class="tiptap-editor"
              value={controls.content()}
              onChange={controls.content.set}
              hidden={O.isNone(activePage())}
            />
          </div>

          {pipe(
            activePage(),
            O.fold(constNull, (page) => (
              <div class="editor-floating-menu flex align-center justify-center gap">
                <button
                  class="button"
                  onClick={() =>
                    updatePage.trigger(page.id, {
                      ...page,
                      content: controls.content(),
                    })
                  }
                  disabled={RR.isPending(updatePage.state())}
                >
                  💾 Save
                </button>
              </div>
            )),
          )}
        </div>
      }
      aside={
        <div class="flex column justify-between">
          {pipe(
            pages(),
            RR.fold2(
              () => <SpinnerOverlay />,
              (err) => <Failure error={err} retry={pages.fetch} />,
              flow(
                list.sort(struct.ord('name', BinderTabPage.ord.name)),
                list.map((page, index) => (
                  <>
                    {index > 0 && <hr />}
                    <div
                      class={cx(
                        'page-treeitem flex align-center justify-between gap-s',
                        isPageOpened(page) && 'is-opened',
                      )}
                    >
                      <Link
                        class="open-button flex-1"
                        href={props.model.getPageOpenLink(page)}
                      >
                        {page.name}
                      </Link>
                      <button
                        class="mini discrete button"
                        onClick={() => props.model.editPageName(page)}
                      >
                        <Icon name="edit" />
                      </button>
                    </div>
                  </>
                )),
                (treeItems) => <nav>{treeItems}</nav>,
              ),
            ),
          )}
          <AddTabPageForm class="padded" model={props.model.addPageForm} />
        </div>
      }
    />
  )
}
