import { solidState } from '@/solidjs/state'
import { O } from '@/std/data'
import { constNull, pipe } from '@/std/function'
import { CancelToast } from '@mindpalace/ui-kit'
import { LettersTabModel } from './letters-tab'
import { LetterContent } from './letters/letter-content.solid'
import { LettersToolbar } from './letters/letters-toolbar.solid'
import { Letters } from './letters/letters.solid'

type Props = {
  model: LettersTabModel
}
export const LettersTab = (props: Props) => {
  const openedLetter = solidState(props.model.openedLetter)

  return (
    <main class="flex column gap">
      <CancelToast action={props.model.archive} message="Archiving letter…" />
      <LettersToolbar
        hidden={O.isSome(openedLetter())}
        filter={props.model.filter}
        pullRssFeeds={props.model.pullRssFeeds}
        subscriptions={props.model.subscriptions}
      />

      <Letters
        hidden={O.isSome(openedLetter())}
        archive={props.model.archive}
        filter={props.model.filter}
        letters={props.model.letters}
        opened={props.model.openedLetter}
        refetch={props.model.letters.fetch}
        getOpenLetterLink={props.model.getOpenLetterLink}
      />

      {pipe(
        openedLetter(),
        O.fold(constNull, (letter) => (
          <div>
            <LetterContent
              letter={letter}
              archive={props.model.archive}
              closeLink={props.model.closeLetterLink}
            />
          </div>
        )),
      )}
    </main>
  )
}
