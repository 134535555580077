import { cast, pipe } from '@/std/function'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { Opaque } from 'type-fest'
import { BinderId } from './Binder'

export type BinderTabId = Opaque<string, 'BinderTabId'>
export const BinderTabId = pipe(
  T.string,
  T.map(cast<string, BinderTabId>, String),
)

export const BinderTab = T.struct({
  id: BinderTabId,
  parentId: BinderId,
  name: T.string,
  tags: T.array(Tag),
  createdAt: T.DateFromString,
})
export type BinderTab = ReturnType<typeof BinderTab>
