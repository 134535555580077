import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Binder, Room } from '../../entity'

export const contract = ApiRouteContract('GET', '/palace/explore', {
  response: {
    status: 200,
    contentType: MimeType.Json,
    codec: T.struct({
      rooms: T.array(Room),
      binders: T.array(Binder),
    }),
  },
})
