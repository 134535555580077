import { History } from '@/std/browser'
import { Context } from '@/std/context'
import { O } from '@/std/data'
import { FetchClient, makeFetchClient } from '@/std/http'
import { ReadonlyState } from '@/std/reactivity'
import { Account } from './entity'

export type ClientContext = {
  history: History
  fetch: FetchClient
}

const [setClientContext, getClientContext, clearClientContext] =
  Context<ClientContext>('ClientContext')

export { clearClientContext, getClientContext }

type Deps = {
  apiUrl: string
  account: ReadonlyState<O.Option<Account>>
  history: History
}
export const provideClientContext = ({ apiUrl, history }: Deps) => {
  setClientContext({
    history,
    fetch: makeFetchClient(apiUrl, () => ({ credentials: 'include' })),
  })
}
