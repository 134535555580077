import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Account } from '../../entity'

const Payload = T.struct({ token: T.string })
export type SignInPayload = ReturnType<typeof Payload>

export const contract = ApiRouteContract('POST', '/sign-in', {
  body: {
    codec: Payload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: Account,
    contentType: MimeType.Json,
  },
})
