import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { pipe } from '@/std/function'
import { ReadonlyState } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { Failure, SpinnerOverlay } from '@mindpalace/ui-kit'
import { ComponentProps, createEffect, splitProps } from 'solid-js'
import { proxyHtml } from './proxy-html'
import './scraped-page.css'

type Props = ComponentProps<'div'> & {
  node: ReadonlyState<RR.RemoteResult<any, Element>>
  retry: () => Promise<void>
}
export const ScrapedPage = (props: Props) => {
  const [, rest] = splitProps(props, ['node', 'retry'])
  const node = solidState(props.node)
  createEffect(() => {
    pipe(node(), RR.map(proxyHtml))
  })
  return (
    <div {...rest} class={cx(props.class, 'scraped-page')}>
      {pipe(
        node(),
        RR.fold(
          () => <>Nothing fetched yet.</>,
          () => <SpinnerOverlay />,
          (err) => <Failure error={err} retry={props.retry} />,
          (node) => {
            if (node instanceof HTMLBodyElement)
              return Array.from(node.children)
            return node
          },
        ),
      )}
    </div>
  )
}
