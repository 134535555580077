const sanitizeLang = (lang?: string) => {
  if (!lang) return 'en-GB'
  if (lang.startsWith('en')) return 'en-GB'
  if (lang.startsWith('fr')) return 'fr-FR'
  return lang
}

export const readText = (text: string, lang?: string) => {
  const utterance = new SpeechSynthesisUtterance(text)
  utterance.lang = sanitizeLang(lang)
  speechSynthesis.speak(utterance)
  return utterance
}
const speakEnded = (utterance: SpeechSynthesisUtterance) => {
  return new Promise<void>((resolve) => {
    utterance.addEventListener('end', () => resolve())
  })
}

const removeAllNodesMatching = (root: Element, selector: string) => {
  for (const node of root.querySelectorAll(selector)) node.remove()
}

export const readNode = async (node: Element, lang?: string) => {
  const clone = node.cloneNode(true)
  if (!(clone instanceof Element)) return
  removeAllNodesMatching(clone, 'pre')
  removeAllNodesMatching(clone, 'style')
  removeAllNodesMatching(clone, '[hidden]')
  for (const anchor of clone.querySelectorAll('a')) {
    if ((anchor.textContent ?? '').length < 3) anchor.remove()
  }

  if (!clone.innerHTML) alert('No text to read, which is odd.')

  const walker = document.createTreeWalker(
    clone, // Root node to start traversal
    NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, // Show both elements and text nodes
    null,
  )

  const nodesToRead = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']
  while (walker.nextNode()) {
    let node = walker.currentNode
    if (nodesToRead.includes(node.nodeName.toLowerCase())) {
      await speakEnded(readText(node.textContent ?? '', lang))
    }
  }
}

// const voices = speechSynthesis.getVoices()
// console.table(
//   voices
//     .filter((v) => v.lang.startsWith('en-GB') || v.lang.startsWith('fr-FR'))
//     .map((voice) => ({
//       default: voice.default,
//       lang: voice.lang,
//       localService: voice.localService,
//       name: voice.name,
//     })),
// )

// const testVoices = async (lang: string, text: string) => {
//   const voices = speechSynthesis.getVoices().filter((v) => v.lang === lang)
//   for (const voice of voices) {
//     console.info(
//       'testing voice',
//       voice.name,
//       voice.lang,
//       '; is default:',
//       voice.default,
//     )
//     const utterance = new SpeechSynthesisUtterance(text)
//     utterance.lang = lang
//     utterance.voice = voice
//     speechSynthesis.speak(utterance)
//     await new Promise((resolve) => {
//       utterance.addEventListener('end', () => resolve(void 0))
//     })
//   }
// }

// const runTest = async () => {
//   await testVoices(
//     'en-GB',
//     'This is some text to check British voice, give me your best!',
//   )
//   await testVoices(
//     'fr-FR',
//     'Voilà un texte pour mettre à l’épreuve les différents speakers francais…',
//   )
// }

// runTest().catch(console.error)
