import { ComponentProps, onCleanup, splitProps } from 'solid-js'
import { SubscriptionsTabModel } from './subscriptions-tab'
import { SubscribeToRssForm } from './subscriptions/subscribe-to-rss-form.solid'
import { SubscriptionList } from './subscriptions/subscriptions-list.solid'

type Props = ComponentProps<'main'> & {
  model: SubscriptionsTabModel
}
export const SubscriptionsTab = (props: Props) => {
  onCleanup(props.model.dispose)

  const [, rest] = splitProps(props, ['model'])
  return (
    <main class="flex column gap">
      <div class="padded-l card flex column gap">
        <h3>Subscribe</h3>
        <SubscribeToRssForm model={props.model.subscribeToRssForm} />
      </div>
      <div class="padded-l card flex column gap">
        <h3>Subscriptions</h3>
        <SubscriptionList
          list={props.model.subscriptions}
          unsubscribe={props.model.unsubscribe}
        />
      </div>
    </main>
  )
}
