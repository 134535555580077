import { Link } from '@/solidjs/router'
import { O } from '@/std/data'
import { Letter } from '@mindpalace/mailbox/entity'
import { ArchiveLettersAction } from '@mindpalace/mailbox/use-case/archive-letter/client'
import { proxyHtml } from '@mindpalace/readings/client/proxy-html'
import { onMount } from 'solid-js'
import { LetterHeader } from './letter-header.solid'

type Props = {
  letter: Letter
  archive: ArchiveLettersAction
  closeLink: string
}
export const LetterContent = (props: Props) => {
  return (
    <div class="inline-padded flex column">
      <header class="block-padded">
        <Link class="discrete button" href={props.closeLink}>
          Close
        </Link>
      </header>
      <hr />
      <div class="block-padded flex column gap-s">
        <LetterHeader letter={props.letter} archive={props.archive} />
        <div
          class="scraped-page"
          ref={(root) => {
            onMount(() => proxyHtml(root))
          }}
          innerHTML={O.toUndefined(props.letter.item.content)}
        />
      </div>
    </div>
  )
}
