import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { Tag } from '@mindpalace/shared/entity'
import { BinderId, BinderTab } from '../../entity'

export type AddBinderTabPayload = ReturnType<typeof AddBinderTabPayload>
const AddBinderTabPayload = T.struct({
  parentId: BinderId,
  name: T.string,
  tags: T.array(Tag),
})

export const contract = ApiRouteContract('POST', '/binder-tab', {
  body: {
    codec: AddBinderTabPayload,
    contentType: MimeType.Json,
  },
  response: {
    status: 200,
    codec: BinderTab,
    contentType: MimeType.Json,
  },
})
