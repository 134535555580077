import { O, list } from '@/std/data'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { debounce, flow, pipe } from '@/std/function'
import { State, computed } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { BinderTab, BinderTabPage } from '@mindpalace/palace/entity'
import { ListBinderTabPagesResource } from '@mindpalace/palace/use-case/list-binder-tab-pages/client'
import { UpdateBinderTabPageAction } from '@mindpalace/palace/use-case/update-binder-tab-page/client'
import { getClientContext } from '@mindpalace/shared/client.context'
import { RemoveBinderTabPageAction } from '../../use-case/remove-binder-tab-page/client'
import { AddTabPageFormModel } from '../page/add-tab-page-form'
import { EditPageFormModel } from '../page/edit-page-form'

export type TabPagesModel = ReturnType<typeof TabPagesModel>
type Deps = {
  tab: BinderTab
}
export const TabPagesModel = ({ tab }: Deps) => {
  const { history } = getClientContext()

  const pages = ListBinderTabPagesResource(tab.id)
  const addPageForm = AddTabPageFormModel({ binderTabId: tab.id })
  const editPageNameForm = State(O.None<EditPageFormModel>())
  const controls = FormGroup({
    content: FormControl('' as string),
    name: FormControl('' as string, [nonEmpty('required')]),
  })
  const updatePage = UpdateBinderTabPageAction(tab.id)
  const removePage = RemoveBinderTabPageAction()
  const activePage = computed([pages, history.current], (pages, url) => {
    return pipe(
      O.struct({
        pages: RR.toOption(pages),
        pageId: O.fromNullable(url.searchParams.get('pageId')),
      }),
      O.flatMap(({ pages, pageId }) =>
        pipe(
          pages,
          list.findFirst((page) => page.id === pageId),
        ),
      ),
    )
  })

  const refetchPages = debounce(500)(pages.fetch)

  const effects = [
    pages.onChange(
      RR.map((pages) => {
        if (O.isSome(activePage())) return
        const [firstPage] = pages
        if (!firstPage) return
        history.push(getPageOpenLink(firstPage))
      }),
    ),
    addPageForm.state.onChange(RR.map(onPageAdded)),
    updatePage.state.onChange(RR.map(onPageUpdated)),
    removePage.state.onChange(RR.map(onPageRemoved)),
    activePage.onChange((page) =>
      controls.content.set(O.toNull(page)?.content ?? ''),
    ),
    activePage.onChange((page) =>
      controls.name.set(O.toNull(page)?.name ?? ''),
    ),
  ]
  const block = history.block(async () => {
    await autoSave()
  })
  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') autoSave()
  }

  return {
    init: () => {
      document.addEventListener('visibilitychange', onVisibilityChange)
    },
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
      document.removeEventListener('visibilitychange', onVisibilityChange)
      block.unblock()
    },

    tab,
    controls,
    editPageNameForm,
    activePage,
    pages,
    addPageForm,
    removePage,
    updatePage,
    getPageOpenLink,
    editPageName: (page) => {
      editPageNameForm.set(
        O.Some(
          EditPageFormModel({
            binderTabPage: flow(
              activePage,
              O.flatMap(O.fromPredicate((p) => p.id === page.id)),
              O.unwrapOr(() => page),
            ),
            onSaved: onPageNameEdited,
          }),
        ),
      )
    },
  }

  function getPageOpenLink(page: BinderTabPage) {
    const url = new URL(history.current())
    url.searchParams.set('pageId', page.id)
    return url.href
  }
  function getPageCloseLink() {
    const url = new URL(history.current())
    url.searchParams.delete('pageId')
    return url.href
  }

  function onPageAdded(page: BinderTabPage) {
    refetchPages()
    history.push(getPageOpenLink(page))
  }
  function onPageRemoved(removed: BinderTabPage) {
    refetchPages()
    if (O.toNull(activePage())?.id !== removed.id) return
    history.push(getPageCloseLink())
  }

  function onPageNameEdited() {
    editPageNameForm.set(O.None())
    refetchPages()
  }

  function onPageUpdated() {
    refetchPages()
  }

  async function autoSave() {
    const page = O.toNull(activePage())
    if (!page) return
    if (page.name === controls.name() && page.content === controls.content())
      return
    return updatePage.trigger(page.id, {
      name: controls.name(),
      tags: page.tags,
      content: controls.content(),
    })
  }
}
