import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteActionToConfirm } from '@/std/remote/remote-action'
import { getClientContext } from '@mindpalace/shared/client.context'
import { RoomId } from '../../entity'
import { contract } from './contract'

export type RemoveRoomAction = ReturnType<typeof RemoveRoomAction>
export const RemoveRoomAction = () => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const remove = (id: RoomId) => client({ params: { id } })
  return RemoteActionToConfirm(remove)
}
