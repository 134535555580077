import { solidState } from '@/solidjs/state'
import { onCleanup } from 'solid-js'
import { LettersTab } from './letters-tab.solid'
import { MailboxModel } from './mailbox'
import { MailboxNavTabs } from './nav-tabs.solid'
import { SubscriptionsTab } from './subscriptions-tab.solid'

export type Props = {
  model: MailboxModel
}
export const Mailbox = (props: Props) => {
  onCleanup(props.model.dispose)
  const activeTab = solidState(props.model.activeTab)
  return (
    <>
      <div class="padded flex justify-center">
        <MailboxNavTabs
          basePath={props.model.basePath}
          activeTab={props.model.activeTab}
        />
      </div>

      <div class="padded">
        {activeTab() === 'subscriptions' && (
          <SubscriptionsTab model={props.model.subscriptionsTab} />
        )}
        {activeTab() === 'letters' && (
          <LettersTab model={props.model.lettersTab} />
        )}
      </div>
    </>
  )
}
