import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { T } from '@/std/type'
import { BinderId, BinderTab } from '../../entity'

export const contract = ApiRouteContract('GET', '/binder/:binderId/tabs', {
  params: T.struct({ binderId: BinderId }),
  response: {
    status: 200,
    codec: T.array(BinderTab),
    contentType: MimeType.Json,
  },
})
