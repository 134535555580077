import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { State } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { ComponentProps, Show, splitProps } from 'solid-js'
import { _Toast } from './Toast.solid'

type Props = ComponentProps<'div'> & {
  submitState: State<RR.RemoteResult<any, any>>
  delay: number
}
export const _SuccessToast = (props: Props) => {
  const [, rest] = splitProps(props, ['submitState', 'delay'])
  const submitState = solidState(props.submitState)
  const dismiss = () => props.submitState.set(RR.NotAsked())

  return (
    <Show when={RR.isOk(submitState())}>
      <_Toast {...rest} delay={props.delay} onDismiss={dismiss}>
        {props.children}
      </_Toast>
      <div {...rest} class={cx(props.class, 'toast flex align-center gap')}>
        <div>{props.children}</div>
        <button
          class="discrete button"
          type="button"
          onClick={dismiss}
          aria-label="Cancel"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </Show>
  )
}
