import { ApiRouteContract } from '@/std/api-contract'
import { MimeType } from '@/std/http'
import { Account } from '../../entity'

export const contract = ApiRouteContract('GET', '/me', {
  response: {
    status: 200,
    codec: Account,
    contentType: MimeType.Json,
  },
})
