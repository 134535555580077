import { justBody } from '@/std/api-contract'
import { FetchApiRouteClient } from '@/std/api-contract/route-client'
import { flow } from '@/std/function'
import { RemoteAction } from '@/std/remote'
import { getClientContext } from '@mindpalace/shared/client.context'
import { BinderTabId, BinderTabPageId } from '../../entity'
import { UpdateBinderTabPagePayload, contract } from './contract'

export const UpdateBinderTabPageAction = (binderTabId: BinderTabId) => {
  const { fetch } = getClientContext()
  const client = flow(FetchApiRouteClient(contract, fetch), justBody)
  const update = (id: BinderTabPageId, body: UpdateBinderTabPagePayload) =>
    client({ params: { id, binderTabId }, body })
  return RemoteAction(update)
}
