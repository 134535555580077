import {
  Curry2,
  Curry3,
  Flow,
  Match,
  MatchOr,
  Not,
  Or,
  Pipe,
} from './function.types'

export type AnyFunction = (...args: any[]) => any

export function identity<T>(value: T): T {
  return value
}

export const cast = identity as <A, B extends A>(a: A) => B

// export function satisfies<T>() {
//   return <U extends T>(value: U): U => value
// }

export const cnst =
  <T extends any>(value: T) =>
  () =>
    value

export const constFalse = cnst(false)
export const constTrue = cnst(true)
export const constNull = cnst(null)
export const constUndefined = cnst(undefined)

/** @type {() => void} */
export const constVoid = constUndefined

type Apply = <Args extends any[], R>(
  ...args: Args
) => (fn: (...args: Args) => R) => R

export const apply: Apply =
  (...args) =>
  (fn) =>
    fn(...args)

type Call = <Args extends any[], R>(
  args: Args,
) => (fn: (...args: Args) => R) => R

export const call: Call = (args) => (fn) => fn(...args)

export const panic = <E>(e: E): never => {
  throw e
}

export const isFunction = (value: unknown): value is AnyFunction =>
  typeof value === 'function'

export const throws = (fn: () => any): boolean => {
  try {
    fn()
    return false
  } catch {
    return true
  }
}

export const toUnknownError = (cause: unknown): Error =>
  cause instanceof Error ? cause : new Error(String(cause))

export const todo =
  (msg = 'TODO') =>
  () =>
    panic(new Error(msg))

export const noop = constVoid

export const flow: Flow = (fn, ...fns) => {
  const composed = fns.reduce(
    (a, b) =>
      (...v) =>
        b(a(...v)),
    fn,
  )
  return Object.assign(composed, { ...fn })
}

export const pipe: Pipe = (initial, ...fns) =>
  // @ts-ignore
  fns.length === 0 ? initial : flow(...fns)(initial)

export const trace =
  <T>(msg: string, map?: (value: T) => any) =>
  (value: T) => (
    console.info(msg),
    console.dir(map?.(value) ?? value, { depth: 30, colors: true }),
    value
  )

export const lazy = <F extends AnyFunction>(factory: F) => {
  // NOTE: cannot use Option<T> here because option module relies on this file too.
  const none = Symbol()
  let result = none
  const proxy = (...args) => {
    if (result === none) result = factory(...args)
    return result
  }
  return proxy as F
}

type Debounced = <Params extends any[]>(
  fn: (...args: any[]) => void,
) => (...args: Params) => void
export const debounce = (ms: number): Debounced => {
  let timeout
  return (fn) =>
    (...args) => {
      if (timeout) clearTimeout(timeout)
      timeout = setTimeout(() => fn(...args), ms)
    }
}

export const defer: Debounced =
  (fn) =>
  (...args) => {
    setTimeout(() => fn(...args), 10)
  }

export type AnyConstructor = new (...args: any[]) => any

/**
 * @template {AnyConstructor} T
 * @template U
 * @param {T} Class
 */
export const isInstanceOf =
  <T extends AnyConstructor>(Class: T) =>
  (value: unknown): value is InstanceType<T> =>
    value instanceof Class

// @ts-ignore I am smarter :p
export const not: Not = (predicate) => (value) => !predicate(value)

export const or: Or =
  (...a) =>
  // @ts-ignore I am smarter :p
  (value) =>
    a.some((refine) => refine(value))

// @ts-ignore I am smarter :p
export const curry2: Curry2 = (fn) => {
  const curried = (...args) => {
    return args.length === 2 ? fn(args[0], args[1]) : (a) => fn(a, args[0])
  }
  // @ts-ignore
  return curried
}

// @ts-ignore I am smarter :p
export const curry3: Curry3 = (fn) => {
  const curried = (...args) => {
    return args.length === 3
      ? fn(args[0], args[1], args[2])
      : (b) => (a) => fn(a, b, args[0])
  }
  // @ts-ignore
  return curried
}

// export const proxy =
//   <Args extends any[]>(f1?: (...args: Args) => void) =>
//   (f2: (...args: Args) => void) =>
//   (...args: Args): void => {
//     f2(...args)
//     f1?.(...args)
//   }

// type AnyCases<Discriminant extends string, DiscriminantValue extends string, T extends { [Key in Discriminant]: DiscriminantValue }> = {
//   [DiscValue in DiscriminantValue]: (value: Extract<T, { [Key in Discriminant]: DiscValue }>) => any
// }
// export const caseOf = <
//   Discriminant extends string,
//   DiscriminantValue extends string,
//   T extends { [Key in Discriminant]: DiscriminantValue },
//   Cases extends AnyCases<Discriminant, DiscriminantValue, T>
// >(
//   discriminant: Discriminant,
//   cases: Cases,
// ) =>
// (value: T) => cases[value[discriminant]](value as any) as ReturnType<Cases[DiscriminantValue]>

export const match: Match = (discriminant) => (cases) => (value) => {
  const discriminator = value[discriminant]
  return cases[discriminator](value as any)
}

export const matchOr: MatchOr = (discriminant) => (cases) => (value) => {
  const discriminator = value[discriminant]
  return (cases[discriminator] ?? cases._)(value as any)
}
export const matchType = match('type')
export const matchTypeOr = matchOr('type')
export const matchId = match('id')
export const matchIdOr = matchOr('id')
export const matchKind = match('kind')
export const matchKindOr = matchOr('kind')

// const toto = {
//   type: 'toto',
//   age: 20,
// } as const
// const tata = {
//   type: 'tata',
//   isDancing: true,
// } as const

// declare const person: typeof toto | typeof tata

// const matchName = match('name')
// pipe(
//   person,
//   match('type')({
//     tata: (value) => value.isDancing.toString(),
//     toto: (value) => value.age.toString(),
//   }),
// )
