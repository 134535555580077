import { solidRemoteAction, solidRemoteResource } from '@/solidjs/reactivity'
import { solidState } from '@/solidjs/state'
import { cx } from '@/std/classNames'
import { O, list } from '@/std/data'
import { flow, pipe } from '@/std/function'
import { State } from '@/std/reactivity'
import { RR } from '@/std/remote'
import { SubscriptionsResource } from '@mindpalace/mailbox/use-case/list-rss-subscriptions/client'
import { PullRssFeedsAction } from '@mindpalace/mailbox/use-case/pull-rss-feeds/client'
import { FailureDialog } from '@mindpalace/ui-kit'
import { ComponentProps, splitProps } from 'solid-js'

type Props = ComponentProps<'div'> & {
  pullRssFeeds: PullRssFeedsAction
  subscriptions: SubscriptionsResource
  filter: State<string>
}
export const LettersToolbar = (props: Props) => {
  const [, rest] = splitProps(props, [
    'pullRssFeeds',
    'subscriptions',
    'filter',
  ])
  const pullRssFeed = solidRemoteAction(props.pullRssFeeds)
  const subscriptions = solidRemoteResource(props.subscriptions)
  const filter = solidState(props.filter)

  return (
    <div {...rest} role="menu" class={cx(props.class, 'card flex wrap gap-s')}>
      <button
        type="button"
        class="button"
        onClick={pullRssFeed.trigger}
        disabled={RR.isPending(pullRssFeed.state())}
      >
        Sync
      </button>

      <FailureDialog
        state={pullRssFeed.state()}
        mapErr={(err) => (
          <>
            {err.name}: {err.message}
          </>
        )}
      />

      <select
        class="control"
        style="width: unset;"
        value={filter()}
        onChange={(e) => filter.set(e.currentTarget.value)}
      >
        <option value="">Filter by Feed</option>
        {pipe(
          subscriptions(),
          RR.fold2(
            () => <option disabled>Loading…</option>,
            (err) => <option disabled>{err.message}</option>,
            flow(
              list.toNonEmpty,
              O.fold(
                () => <option disabled>No subscriptions</option>,
                list.map((sub) => (
                  <option value={sub.host.replace('www.', '')}>
                    {sub.host.replace('www.', '')}
                  </option>
                )),
              ),
            ),
          ),
        )}
      </select>
    </div>
  )
}
