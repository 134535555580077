import { preventDefault } from '@/std/browser'
import { FormControl, FormGroup, nonEmpty } from '@/std/form-control'
import { ReadonlyState, State } from '@/std/reactivity'
import { RR } from '@/std/remote'

export type RoomFormModel = ReturnType<typeof RoomFormModel>
type FormValues = { name: string }
type Deps = {
  initialValues?: FormValues
  state: ReadonlyState<RR.RemoteResult<any, any>>
  submit: (values: FormValues) => Promise<unknown>
  onSaved?: () => void
}
export const RoomFormModel = ({
  initialValues,
  state,
  submit,
  onSaved,
}: Deps) => {
  const controls = FormGroup({
    name: FormControl(initialValues?.name ?? '', [nonEmpty('required')]),
  })
  const submitted = State(false)

  const effects = [controls.name.onChange(() => submitted.set(false))]

  return {
    dispose: () => {
      effects.forEach((effect) => effect.unlisten())
    },

    controls,
    submitted,
    state,
    submit: preventDefault(async () => {
      controls.markTouched()
      submitted.set(true)
      if (!controls.isValid()) return

      await submit({ name: controls.name() })
      if (RR.isErr(state())) return
      onSaved?.()
      controls.name.set('')
      submitted.set(false)
    }),
  }
}
